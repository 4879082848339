import {
  createStyles,
  Title,
  Text,
  Image,
  Box,
  Stack,
  List,
} from '@mantine/core';

import aboutPhoto1 from '../assets/about-photo-1.jpg';
import aboutPhoto2 from '../assets/about-photo-2.jpg';
import aboutPhoto3 from '../assets/about-photo-3.jpg';

import { themeColors } from '../config/themeSettings';

import ComingSoonText from './components/ComingSoonText';

const AboutContent = () => {
  const { classes } = useStyles();

  return (
    <div>
      <section className={classes.header}>
        <Title order={2} className={classes.title}>
          About Say Help
        </Title>
      </section>
      <div className={classes.container}>
        <Box className={classes.sectionCenter}>
          <div className={classes.photosFlex}>
            <Stack className={classes.photosContent}>
              <Image src={aboutPhoto1} />
              <Box className={classes.textLayout}>
                <Text size="xl">The Tragic Motivation</Text>
                <Text>
                  A dedicated police officer sought to make a meaningful
                  difference in public safety. The officer felt compelled to
                  find a solution that could improve both emergency response
                  times and personal safety in high-stakes situations.
                </Text>
                <Text>
                  Driven by this commitment, the idea for “Say Help” was born—a
                  voice-activated, patent-pending safety application designed to
                  provide immediate assistance in critical moments. After years
                  of development, the app emerged as a powerful tool aimed at
                  saving lives by enabling faster and more effective responses.
                </Text>
                <Text>
                  With its mission to safeguard individuals in urgent
                  situations, “Say Help” aspires to bring peace of mind to users
                  and their loved ones, one life at a time.
                </Text>
              </Box>
            </Stack>
            <Stack className={classes.photosContent}>
              <Image src={aboutPhoto2} />
              <Box className={classes.textLayout}>
                <Text size="xl">
                  When you don't have time to dial or cannot access your phone
                </Text>
                <Text>
                  Say <b>"Help, Help, Help".</b> At a moment’s notice, the
                  safety of any individual can be challenged. It may be the
                  result of an unfortunate situation or an encounter with
                  someone that feels threatening. Our natural reaction is to
                  grab a phone and text or dial security companies, fire,
                  ambulance or police for emergency services support.
                </Text>

                <Text>
                  But what can you do when that device is out of reach, or the
                  situation will not allow you to dial for help? The answer is
                  simple, just say, “Help Help Help”!!
                </Text>

                <Text>
                  The Say Help application, once activated in this very
                  effective manner, signals for help via text messages. Your
                  device GPS provides the location for emergency services,
                  family, friends or to our emergency call center, and they are
                  on their way to help!
                </Text>
              </Box>
            </Stack>
            <Stack className={classes.photosContent}>
              <Image src={aboutPhoto3} />
              <Box className={classes.textLayout}>
                <Text size="xl">
                  Advanced Emergency Call Center <ComingSoonText />
                </Text>

                <Text>
                  Multiple redundancies for
                  <List
                    withPadding
                    style={{
                      listStylePosition: 'outside',
                    }}>
                    <List.Item>Power</List.Item>
                    <List.Item>Resources</List.Item>
                    <List.Item>Call center locations</List.Item>
                  </List>
                </Text>

                <Text>
                  Routes calls to the closet dispatch center, based on your GPS
                  location
                </Text>
                <Text italic>
                  <sup>*</sup> Available with Premium and University programs
                </Text>
              </Box>
            </Stack>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default AboutContent;

const useStyles = createStyles(theme => ({
  root: {
    fontFamily: 'Poppins',
    backgroundColor: themeColors.white,
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
  },

  container: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },

  containerDark: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,

    position: 'relative',
  },

  flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',

    // General Gap between header buttons title and elements
    gap: 20,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    gridTemplateRows: 'auto',
    justifyContent: 'space-between',

    '@media (max-width: 425px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      justifyContent: 'center',
      alignContent: 'center',
      justifyItems: 'center',
    },
    '@media (max-width: 1000px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      justifyContent: 'center',
      alignContent: 'center',
      justifyItems: 'center',
      gap: 20,
    },
  },
  terms: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    // alignItems: 'center',
    justifyContent: 'center',

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 50,
    },

    '@media (max-width: 425px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 0,
    },
  },

  menuFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  menu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // alignItems: 'center',
    justifyContent: 'center',
    gpa: 10,
    borderRadius: 6,
    backgroundColor: themeColors.menu,
    height: 40,

    '@media (max-width: 550px)': {
      display: 'none',
    },
  },

  menuLink: {
    color: themeColors.white,
    paddingLeft: 34,
    paddingRight: 34,

    borderRight: '1px solid #D8D8D8',

    ':last-child': {
      borderRight: 'none',
    },
  },

  menuLinkActive: {
    color: themeColors.white,
    fontWeight: 'bold',
    paddingLeft: 34,
    paddingRight: 34,

    borderRight: '1px solid #D8D8D8',

    ':last-child': {
      borderRight: 'none',
    },

    '@media (max-width: 425px)': {
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: 16,
    },
  },

  badgesTop: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // alignItems: 'center',
    justifyContent: 'center',
    gpa: 20,

    '@media (max-width: 425px)': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 20,
    },

    '@media (min-width: 426px)': {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 20,
    },

    '@media (min-width: 1023px)': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 20,
    },
  },

  anchor: {
    color: themeColors.anchor,
    fontSize: 14,
  },
  badges: {
    zIndex: 100,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 20,
  },

  apppreview: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 20,
  },

  apppreviewPosition: {
    // position: 'absolute',
    // top: 0,
  },

  header: {
    textAlign: 'center',
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 900px)',
    gridTemplateRows: 'auto',
    justifyContent: 'center',
    justifyItems: 'center',
    gap: 20,
    // gridColumn:

    // maxWidth: 1000,
  },

  title: {
    fontFamily: 'Poppins',
    // fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.anchor,
  },

  titleWhite: {
    fontFamily: 'Poppins',
    fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    // maxWidth: 900,
    textAlign: 'center',
    color: themeColors.white,

    '@media (min-width: 768px) and (max-width: 1023px) ': {
      fontSize: 20,
    },
    '@media (max-width: 767px)': {
      fontSize: 27,
    },
  },
  subtitle: {
    fontFamily: 'Poppins',
    // fontSize: 13,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.15,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.anchor,
  },

  redTitle: {
    fontFamily: 'Poppins',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    letterSpacing: 4,
    textAlign: 'center',
    color: '#ec1d23',
    textTransform: 'uppercase',
  },

  ctaTitle: {
    fontFamily: 'Poppins',
    fontSize: '0.85rem',
    fontWeight: 'bold',
    letterSpacing: 1,
    textAlign: 'center',
    // color: '#ec1d23',
    textTransform: 'uppercase',
  },

  redbutton: {
    // updated fonts
    fontSize: 14,
    fontWeight: 'bold',
    color: themeColors.white,
    backgroundColor: '#EC1D23',
    width: 250,
  },

  rightIcon: {
    marginLeft: 0,
  },

  containerGrey: {
    backgroundColor: themeColors.backgound,
    // height: 900,
    // minHeight: 400,
  },

  darksectionMiddle: {
    paddingLeft: 20,
    paddingRight: 20,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,

    '@media (max-width: 767px) ': {
      paddingBottom: 60,
    },

    '@media (min-width: 768px) and (max-width: 1023px)': {
      paddingBottom: 80,
    },
    '@media (min-width: 1024px) ': {
      paddingBottom: 80,
    },
  },

  darksection: {
    paddingLeft: 20,
    paddingRight: 20,
  },

  photosContent: {
    maxWidth: '33%',
    '@media (max-width: 768px)': {
      maxWidth: '100%',
    },
  },

  textLayout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 20,

    '@media (max-width: 768px)': {
      maxWidth: '100%',
      marginLeft: '10vw',
      marginRight: '10vw',
    },

    '@media (max-width: 325px)': {
      maxWidth: '100%',
      marginLeft: 0,
      marginRight: 0,
    },
  },

  positionUp: {
    width: '100%',
    position: 'absolute',
    bottom: '-20vw',
    left: 0,
    right: 0,

    '@media (min-width: 1024px)': {
      bottom: '-210px',
    },
  },

  appPreview: {
    maxWidth: 854,
    width: '80%',
    height: 'auto',
    margin: '0 auto',

    '@media (min-width: 768px) and (max-width: 1023px)': {
      height: 'auto',
      margin: '0 auto',
      width: '80%',
    },
    '@media (max-width: 767px) ': {
      height: 'auto',
      margin: '0 auto',
      width: '80%',
    },
  },

  space: {
    // height: 1215,
    // minHeight: 400,
    paddingBottom: '25vw',

    '@media (min-width: 1024px)': {
      paddingBottom: '150px',
    },
  },

  sectionCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '90vw',
    maxWidth: 1000,
  },

  photosFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    flexBasis: '33.3333333333%',
    width: '100%',

    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },

  contentflex: {
    // paddingTop: 30,
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 1fr) minmax(min-content, 1fr)',
    gridTemplateRows: 'auto',

    justifyContent: 'space-evenly',

    gap: 20,

    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
    },
  },

  contentflexReverse: {
    paddingTop: 50,
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',

    gap: 20,

    '@media (max-width: 767px)': {
      flexDirection: 'column',
    },
  },

  titleRedLine: {
    // fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.88,
    letterSpacing: 'normal',
    color: themeColors.text,

    borderLeft: '6px solid #EC1D23',
    paddingLeft: 20,
  },

  description: {
    // fontSize: 13,
    fontWeight: 'normal',
    lineHeight: 2.15,
    letterSpacing: 'normal',
    paddingLeft: 30,
    paddingRight: 30,
  },

  containerDarkImage: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 20,
    paddingBottom: 20,

    '@media (min-width: 1024px)': {
      // paddingTop: 100,
      // paddingBottom: 100,
    },
  },

  titleOutline: {
    fontFamily: 'Poppins',
    WebkitTextStroke: '1px #ec1c24',
    fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: themeColors.text,
  },

  advantagesFlex: {
    // paddingTop: 50,

    // paddingLeft: 30,
    // paddingRight: 30,

    '@media (max-width: 768px)': {
      paddingTop: 20,
    },

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    gap: 20,
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },

  descriptionWhite: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: 'normal',

    color: themeColors.white,
    '@media (max-width: 767px)': {
      textAlign: 'center',
      fontSize: 16,
      lineHeight: 2.13,
    },
    '@media (min-width: 1023px)': {
      fontSize: 20,
    },
  },

  advantagesDescriptionFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 350,
    '@media (min-width: 768px)': {
      alignItems: 'flex-start',
    },
  },

  advFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 50,
    paddingBottom: 50,
    paddingLeft: 20,
    paddingRight: 20,

    '@media (min-width: 768px)': {
      paddingLeft: 30,
      paddingRight: 30,
    },
    '@media (min-width: 1024px)': {
      paddingTop: 100,
      paddingBottom: 100,
    },
  },

  phonePreview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 20,

    '@media (min-width: 768px)': {
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
  },

  phonePreviewImages: {
    maxWidth: 150,
    width: '80%',
    // height: 'auto',
    // margin: '0 auto',
  },

  phonePreviewDescriptionTablet: {
    paddingTop: 20,

    '@media (min-width: 1024px)': {
      paddingTop: 50,
      paddingBottom: 100,
      maxWidth: 450,
    },
  },

  phonePreviewDescription: {
    paddingTop: 20,

    '@media (min-width: 768px)': {
      paddingTop: 130,
      paddingBottom: 130,
      maxWidth: 350,
    },
  },

  appPreviewImages: {
    maxWidth: 550,
    width: '100%',
  },

  flexReverse: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },

  flexBottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    // General Gap between header buttons title and elements
    gap: 20,

    paddingLeft: 50,
    paddingRight: 50,
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
  },

  flexBottomLogo: {
    alignItems: 'center',
    '@media (min-width: 768px)': {
      alignItems: 'flex-start',
    },
  },

  termsBottom: {
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // alignItems: 'center',
    justifyContent: 'space-between',

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 50,
    },
  },

  termsSocial: {
    zIndex: 100,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    gap: 20,

    // '@media (min-width: 768px)': {
    //   flexDirection: 'row',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    //   gap: 50,
    // },
  },

  cta: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.7,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.red,

    '@media (min-width: 768px)': {
      fontSize: 20,
    },
  },

  bottomImagePosition: {
    position: 'absolute',
    bottom: 0,
    '@media (max-width: 767px)': {
      // width: 1100,
      // height: 300,
      minWidth: 1020,
      objectPosition: 'center',
      imagePosition: 'center',
    },
    '@media (min-width: 768px) and (max-width: 1023px)': {
      minWidth: 1020,

      objectPosition: 'center',
      objectGit: 'cover',
      imagePosition: 'center',
    },

    '@media (min-width: 1024px)': {
      maxWidth: 1600,
      objectPosition: 'center',
      imagePosition: 'center',
    },
  },

  containerGreyBottom: {
    backgroundColor: themeColors.backgound,
    height: 110,
    position: 'relative',
    '@media (min-width: 768px)': {
      height: 110,
    },
    overflowX: 'clip',
    // overflowY: 'initial',
  },

  containerDarkBottom: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 200,
    position: 'relative',
    '@media (min-width: 768px)': {
      paddingTop: 160,
    },
  },
  redTitleBox: {
    paddingTop: '25vw',
    '@media (min-width: 1024px)': {
      paddingTop: 250,
    },
  },
}));
