import {
  createStyles,
  Title,
  Text,
  Button,
  Center,
  Space,
  Anchor,
  Image,
  Box,
  Stack,
  BackgroundImage,
  Modal,
  UnstyledButton,
  Menu,
  Group,
  Divider,
  List,
  TextInput,
  Textarea,
  ActionIcon,
  Notification,
  Loader,
  Table,
  Container,
  Grid,
  Accordion,
} from '@mantine/core';
import { ElfsightWidget } from 'react-elfsight-widget';
import { themeColors } from '../../config/themeSettings';
import { Link, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { IconArrowUpRight, IconCheck, IconX } from '@tabler/icons';

import { useForm } from 'react-hook-form';

import image from '../../pages/assets/datingArticlePic.jpg';

const DatingAppSafety = () => {
  const { classes } = useStyles();

  return (
    <div>
      <div className={classes.dateCategory}>
        January 5, 2024 | Dating App Safety
      </div>
      <section className={classes.header}>
        <Title order={1} className={classes.title}>
          Navigating Dating App Safety
        </Title>
      </section>
      <div className={classes.container}>
        <Box className={classes.sectionCenter}>
          <Image src={image} />
          <div className={classes.imageAttribution}>
            Image by{' '}
            <a href="https://www.freepik.com/free-photo/integrate-scene-mobile-screen-frame_58530518.htm#page=2&query=using%20dating%20app&position=20&from_view=search&track=ais&uuid=52e922b6-e669-4602-b60e-38556c5d2ab4">
              Freepik
            </a>
          </div>
          <section className={classes.headerQuestion}>
            <Title order={3} className={classes.title}>
              How Say Help Can Protect You on Your Dates
            </Title>
          </section>
          <section className={classes.paragraph}>
            <Space h="md" />
            <Text>
              In the era of digital romance, dating apps like Tinder, Bumble,
              and Hinge have revolutionized how we meet potential partners.
              However, the convenience of swiping right comes with its own set
              of risks. Reports of incidents ranging from uncomfortable
              encounters to serious crimes linked to dating app meetups are not
              uncommon. In response to these safety concerns, there's a growing
              need for innovative solutions like the Say Help app.
            </Text>
            <Space h="md" />
            <Title order={4} className={classes.titleLeft}>
              The Risks of Dating Apps
            </Title>
            <Space h="md" />

            <List withPadding className={classes.regularList}>
              <List.Item>
                A recent study by Pew Research indicated women under 50 who have
                used dating sites or apps (56%) say they have been sent a
                sexually explicit message or image they didn’t ask for, and
                about four-in-ten have had someone continue to contact them
                after they said they were not interested (43%) or have been
                called an offensive name (37%). Roughly one-in-ten of this group
                (11%) have received threats of physical harm.
              </List.Item>
              <List.Item>
                In the UK, a startling 10% of all sexual assault cases are now
                linked to encounters initiated through online dating platforms.
                This statistic underscores the urgent need for caution and
                protective measures when engaging with potential partners
                online.{' '}
              </List.Item>
              <List.Item>
                25 cases of dating app stories can be seen{' '}
                <a
                  href="https://list25.com/25-terrifying-dating-app-stories-that-are-actually-true/"
                  target="_blank"
                  rel="noopener noreferrer">
                  here.
                </a>
                This collection offers a chilling insight into the real-world
                risks associated with online dating. These true stories serve as
                a stark reminder of the need for vigilance and safety
                precautions when engaging with others through dating apps.
              </List.Item>
              <List.Item>
                10 Cases of dating app murders can be read{' '}
                <a
                  href="https://listverse.com/2022/01/10/10-more-dating-app-murders/"
                  target="_blank"
                  rel="noopener noreferrer">
                  here.
                </a>
                This compilation provides a sobering perspective on the extreme
                dangers that can arise from online dating encounters,
                underscoring the critical importance of exercising caution and
                using safety measures when meeting someone from a dating app.{' '}
              </List.Item>
            </List>
            <Space h="md" />
            <Title order={4} className={classes.titleLeft}>
              Why is Say Help a Game-Changer?
            </Title>
            <Space h="md" />
            <Text>
              Say Help is an emergency alert application designed to enhance
              personal safety in real-life situations. It offers an extra layer
              of protection and peace of mind when people decide to take any
              relationships from the digital world to the physical world. Here’s
              how Say Help can make a difference:
            </Text>
            <Space h="md" />
            <List withPadding className={classes.regularList}>
              <List.Item>
                <strong>Voice-Activated and Push-Button Alerts:</strong> In
                situations where discreet action is required, Say Help allows
                users to initiate an emergency alert using a voice command or by
                push-button. This feature is especially beneficial in situations
                where someone might feel uneasy or threatened but is unable to
                openly ask for assistance. The choice of activating an alert
                through either voice command or a push-button provides a
                discreet yet effective way to seek help.
              </List.Item>
              <List.Item>
                <strong>Real-Time Streaming Alert Room:</strong> The app
                provides a platform for emergency contacts and monitoring
                centers to access real-time audio and visual feeds and GPS
                tracking during an emergency. This feature ensures that help is
                informed and can respond accurately to the user’s location.
              </List.Item>
              <List.Item>
                <strong>Emergency Contact Notification:</strong> Users have the
                ability to select up to five emergency contacts who will be
                immediately notified through SMS or WhatsApp when the user
                initiates an alert. Emergency contacts will be sent a link to
                the users “Alert Room” where they can view the user’s profile,
                video, audio, and location.
              </List.Item>
              <List.Item>
                <strong>Secure Storage of Video and Alert Room Data:</strong>{' '}
                Say Help ensures that all video footage and details from the
                alert room are stored securely. This is a critical feature in
                the event that the user's phone is lost, damaged, or taken
                during an emergency. With this secure storage, the evidence
                remains intact and accessible, providing an additional layer of
                security and accountability.
              </List.Item>
              <List.Item>
                <strong>Remote Camera Control:</strong> The Say Help app offers
                a feature where viewers in the alert room can remotely toggle
                between different camera views. This functionality is
                particularly useful if the front camera view is obstructed,
                allowing for improved situational awareness and a more
                comprehensive understanding of the emergency context.
              </List.Item>
            </List>
            <Space h="md" />
            <Text>
              In addition to utilizing advanced tools such as Say Help for
              emergency scenarios, it's crucial to adopt various other safety
              practices while engaging in the online dating scene. These
              measures play a significant role in protecting yourself in this
              digital dating landscape. Here are some essential safety tips for
              navigating the world of online dating:
            </Text>
            <Space h="md" />

            <List withPadding type="ordered" className={classes.regularList}>
              <List.Item>
                <strong>Verify Profiles:</strong> Be cautious of profiles with
                minimal information or only one photo. Consider using Google
                reverse image search to verify profile photos, as scammers often
                use pictures found online.
              </List.Item>
              <List.Item>
                <strong>Keep Personal Information Private:</strong> Avoid
                sharing personal details like your full name, phone number,
                email, or home address. Stick to communicating through the
                dating app's messaging system until you feel confident about the
                person's intentions.
              </List.Item>
              <List.Item>
                <strong>Arrange Public First Meetings:</strong> Always meet in
                public spaces for initial dates. Choose well-lit, busy locations
                like cafes, parks, or restaurants. Avoid private or isolated
                places.
              </List.Item>
              <List.Item>
                <strong>Inform Friends or Family:</strong> Let someone you trust
                know about your date, including who you’re meeting, where, and
                when. Consider sharing your location with them through your
                phone.
              </List.Item>
              <List.Item>
                <strong>Stay Sober:</strong> Keep a clear mind by limiting
                alcohol consumption or abstaining altogether. Being under the
                influence can impair your judgment and make you vulnerable.
              </List.Item>
              <List.Item>
                <strong>Plan Your Own Transportation:</strong> Use your own mode
                of transportation to and from the date. Relying on someone else
                for a ride can leave you in a vulnerable position.
              </List.Item>
              <List.Item>
                <strong>Listen to Your Instincts:</strong> If something feels
                off, trust your gut. Don’t feel obligated to stay if you’re
                uncomfortable. Your safety is more important than politeness.
              </List.Item>
              <List.Item>
                <strong>Use Emergency Safety Apps:</strong> Consider using
                safety apps like Say Help. These apps can send alerts to
                emergency contacts or authorities if you find yourself in an
                unsafe situation.
              </List.Item>
              <List.Item>
                <strong>Research Your Date:</strong> Before meeting, do a quick
                search online to see if anything alarming comes up. Checking
                social media profiles can also give a better sense of who they
                are.
              </List.Item>
              <List.Item>
                <strong>Report Suspicious Behavior:</strong> If you encounter
                any suspicious behavior, report it to the dating app. Most apps
                have a feature for reporting profiles that violate their terms
                of use.
              </List.Item>
            </List>
            <Space h="md" />
            <Text>
              By following these safety tips, you can better protect yourself
              from potential dangers while enjoying the benefits of online
              dating. Always remember that your safety is paramount, and taking
              these precautions can help ensure a more secure and enjoyable
              dating experience.{' '}
            </Text>
            <Space h="md" />
            <Text>
              With the rise in dating app usage, and consequently, related
              safety incidents, integrating applications like Say Help into the
              dating scene is not just innovative but necessary. Dating apps
              could potentially collaborate with Say Help to incorporate these
              safety features directly into their platforms, offering users a
              built-in safety mechanism.
            </Text>
            <Space h="md" />
            <Text>
              In conclusion, while dating apps have opened new avenues for
              finding love and companionship, they also necessitate a heightened
              awareness of personal safety. Applications like Say Help offer a
              viable solution to mitigate these risks, empowering users to take
              proactive steps towards their safety. As we navigate the complex
              world of online dating, tools like Say Help become indispensable
              in ensuring that the search for love remains safe and enjoyable.
            </Text>
            <Space h="md" />
          </section>
          <Divider></Divider>
          <Text className={classes.footNote}>
            Footnotes: Combination of derivative and original content. Sources:
            www.google.com, datingarmory.com, list25.com, listverse.com,
            pewresearch.org
          </Text>
        </Box>
      </div>
    </div>
  );
};

export default DatingAppSafety;

const useStyles = createStyles(theme => ({
  root: {
    fontFamily: 'Poppins',
    backgroundColor: themeColors.white,
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
  },

  dateCategory: {
    fontSize: 14,
    fontWeight: 'normal',
    marginBottom: 10,
    textAlign: 'center',
    color: '#757575',
  },

  differences: {
    width: '100%',
  },

  wrapper: {
    paddingTop: `calc(${theme.spacing.xl}px * 2)`,
    paddingBottom: `calc(${theme.spacing.xl}px * 2)`,
  },

  item: {
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
  },

  container: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },

  containerDark: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,

    position: 'relative',
  },

  flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',

    // General Gap between header buttons title and elements
    gap: 20,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    gridTemplateRows: 'auto',
    justifyContent: 'space-between',

    '@media (max-width: 425px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      justifyContent: 'center',
      alignContent: 'center',
      justifyItems: 'center',
    },
    '@media (max-width: 1000px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      justifyContent: 'center',
      alignContent: 'center',
      justifyItems: 'center',
      gap: 20,
    },
  },
  terms: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    // alignItems: 'center',
    justifyContent: 'center',

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 50,
    },

    '@media (max-width: 425px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 0,
    },
  },

  menuFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  menu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // alignItems: 'center',
    justifyContent: 'center',
    gpa: 10,
    borderRadius: 6,
    backgroundColor: themeColors.menu,
    height: 40,

    '@media (max-width: 550px)': {
      display: 'none',
    },
  },

  menuLink: {
    color: themeColors.white,
    paddingLeft: 34,
    paddingRight: 34,

    borderRight: '1px solid #D8D8D8',

    ':last-child': {
      borderRight: 'none',
    },
  },

  menuLinkActive: {
    color: themeColors.white,
    fontWeight: 'bold',
    paddingLeft: 34,
    paddingRight: 34,

    borderRight: '1px solid #D8D8D8',

    ':last-child': {
      borderRight: 'none',
    },

    '@media (max-width: 425px)': {
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: 16,
    },
  },

  badgesTop: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // alignItems: 'center',
    justifyContent: 'center',
    gpa: 20,

    '@media (max-width: 425px)': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 20,
    },

    '@media (min-width: 426px)': {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 20,
    },

    '@media (min-width: 1023px)': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 20,
    },
  },

  anchor: {
    color: themeColors.anchor,
    fontSize: 14,
  },
  badges: {
    zIndex: 100,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 20,
  },

  apppreview: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 20,
  },

  apppreviewPosition: {
    // position: 'absolute',
    // top: 0,
  },

  header: {
    textAlign: 'center',
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 900px)',
    gridTemplateRows: 'auto',
    justifyContent: 'center',
    justifyItems: 'center',
    gap: 20,
    // gridColumn:

    // maxWidth: 1000,
  },

  headerQuestion: {
    textAlign: 'center',
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 900px)',
    gridTemplateRows: 'auto',
    justifyContent: 'center',
    justifyItems: 'center',
    gap: 20,
    marginTop: 20,
    // gridColumn:

    // maxWidth: 1000,
  },

  boldList: {
    fontWeight: 'bold',
    paddingRight: 20,
  },
  regularList: {
    fontWeight: 'normal',
    paddingRight: 20,
  },

  nestedList: {
    fontWeight: 'normal',
  },

  footNote: {
    fontSize: 12,
    marginTop: 20,
    color: '#757575',
  },

  imageAttribution: {
    fontSize: 12,
    marginTop: 10,
    color: '#757575',
  },

  title: {
    fontFamily: 'Poppins',
    // fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.anchor,
  },

  titleLeft: {
    fontFamily: 'Poppins',
    // fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    //textAlign: 'center',
    color: themeColors.anchor,
  },

  paragraph: {
    fontFamily: 'Poppins',
    // fontSize: 26,
    //fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    //textAlign: 'center',
    color: themeColors.anchor,
  },

  titleWhite: {
    fontFamily: 'Poppins',
    fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    // maxWidth: 900,
    textAlign: 'center',
    color: themeColors.white,

    '@media (min-width: 768px) and (max-width: 1023px) ': {
      fontSize: 20,
    },
    '@media (max-width: 767px)': {
      fontSize: 27,
    },
  },
  subtitle: {
    fontFamily: 'Poppins',
    // fontSize: 13,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.15,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.anchor,
  },

  redTitle: {
    fontFamily: 'Poppins',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    letterSpacing: 4,
    textAlign: 'center',
    color: '#ec1d23',
    textTransform: 'uppercase',
  },

  ctaTitle: {
    fontFamily: 'Poppins',
    fontSize: '0.85rem',
    fontWeight: 'bold',
    letterSpacing: 1,
    textAlign: 'center',
    // color: '#ec1d23',
    textTransform: 'uppercase',
  },

  redbutton: {
    // updated fonts
    fontSize: 14,
    fontWeight: 'bold',
    color: themeColors.white,
    backgroundColor: '#1E1E1E',
    width: 250,
  },

  rightIcon: {
    marginLeft: 0,
  },

  containerGrey: {
    backgroundColor: themeColors.backgound,
    // height: 900,
    // minHeight: 400,
  },

  darksectionMiddle: {
    paddingLeft: 20,
    paddingRight: 20,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,

    '@media (max-width: 767px) ': {
      paddingBottom: 60,
    },

    '@media (min-width: 768px) and (max-width: 1023px)': {
      paddingBottom: 80,
    },
    '@media (min-width: 1024px) ': {
      paddingBottom: 80,
    },
  },

  darksection: {
    paddingLeft: 20,
    paddingRight: 20,
  },

  photosContent: {
    maxWidth: '33%',
    '@media (max-width: 768px)': {
      maxWidth: '390px',
    },
  },

  positionUp: {
    width: '100%',
    position: 'absolute',
    bottom: '-20vw',
    // top: -330,
    left: 0,
    right: 0,
    // margin: '0 auto',

    '@media (min-width: 1024px)': {
      bottom: '-210px',
    },
    // '@media (min-width: 768px) and (max-width: 1023px)': {
    //   top: '-38vw',
    // },
    // '@media (max-width: 767px)': {
    //   top: '-38vw',
    // },
    // '@media (min-width: 1024px)': {
    //   top: '-310px',
    //   // top: '-330px',
    //   // left: '20%',
    //   // right: '20%',
    // },
    // position: 'absolute',
    // top: -100,
    // '@media (min-width: 768px) and (max-width: 1023px)': {
    //   transform: 'translate(0,-33vw)',
    // },
    // '@media (max-width: 767px)': {
    //   transform: 'translate(0,-33vw)',
    // },
    // '@media (min-width: 1024px)': {
    //   transform: 'translate(0,-337px)',
    // },
  },

  appPreview: {
    maxWidth: 854,
    width: '80%',
    height: 'auto',
    margin: '0 auto',

    '@media (min-width: 768px) and (max-width: 1023px)': {
      height: 'auto',
      margin: '0 auto',
      width: '80%',
    },
    '@media (max-width: 767px) ': {
      height: 'auto',
      margin: '0 auto',
      width: '80%',
    },
  },

  space: {
    // height: 1215,
    // minHeight: 400,
    paddingBottom: '25vw',

    '@media (min-width: 1024px)': {
      paddingBottom: '150px',
    },
  },

  sectionCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '90vw',
    maxWidth: 900,
  },

  photosFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    flexBasis: '33.3333333333%',
    width: '100%',

    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },

  contentflex: {
    // paddingTop: 30,
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 1fr) minmax(min-content, 1fr)',
    gridTemplateRows: 'auto',

    justifyContent: 'space-evenly',

    gap: 20,

    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
    },
  },

  contentflexReverse: {
    paddingTop: 50,
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',

    gap: 20,

    '@media (max-width: 767px)': {
      flexDirection: 'column',
    },
  },

  titleRedLine: {
    // fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.88,
    letterSpacing: 'normal',
    color: themeColors.text,

    borderLeft: '6px solid #EC1D23',
    paddingLeft: 20,
  },

  description: {
    // fontSize: 13,
    fontWeight: 'normal',
    lineHeight: 2.15,
    letterSpacing: 'normal',
    paddingLeft: 30,
    paddingRight: 30,
  },

  containerDarkImage: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 20,
    paddingBottom: 20,

    '@media (min-width: 1024px)': {
      // paddingTop: 100,
      // paddingBottom: 100,
    },
  },

  titleOutline: {
    fontFamily: 'Poppins',
    WebkitTextStroke: '1px #ec1c24',
    fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: themeColors.text,
  },

  advantagesFlex: {
    // paddingTop: 50,

    // paddingLeft: 30,
    // paddingRight: 30,

    '@media (max-width: 768px)': {
      paddingTop: 20,
    },

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    gap: 20,
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },

  descriptionWhite: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: 'normal',

    color: themeColors.white,
    '@media (max-width: 767px)': {
      textAlign: 'center',
      fontSize: 16,
      lineHeight: 2.13,
    },
    '@media (min-width: 1023px)': {
      fontSize: 20,
    },
  },

  advantagesDescriptionFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 350,
    '@media (min-width: 768px)': {
      alignItems: 'flex-start',
    },
  },

  advFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 50,
    paddingBottom: 50,
    paddingLeft: 20,
    paddingRight: 20,

    '@media (min-width: 768px)': {
      paddingLeft: 30,
      paddingRight: 30,
    },
    '@media (min-width: 1024px)': {
      paddingTop: 100,
      paddingBottom: 100,
    },
  },

  phonePreview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 20,

    '@media (min-width: 768px)': {
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
  },

  phonePreviewImages: {
    maxWidth: 150,
    width: '80%',
    // height: 'auto',
    // margin: '0 auto',
  },

  phonePreviewDescriptionTablet: {
    paddingTop: 20,

    '@media (min-width: 1024px)': {
      paddingTop: 50,
      paddingBottom: 100,
      maxWidth: 450,
    },
  },

  phonePreviewDescription: {
    paddingTop: 20,

    '@media (min-width: 768px)': {
      paddingTop: 130,
      paddingBottom: 130,
      maxWidth: 350,
    },
  },

  appPreviewImages: {
    maxWidth: 550,
    width: '100%',
  },

  flexReverse: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },

  flexBottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    // General Gap between header buttons title and elements
    gap: 20,

    paddingLeft: 50,
    paddingRight: 50,
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
  },

  flexBottomLogo: {
    alignItems: 'center',
    '@media (min-width: 768px)': {
      alignItems: 'flex-start',
    },
  },

  termsBottom: {
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // alignItems: 'center',
    justifyContent: 'space-between',

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 50,
    },
  },

  termsSocial: {
    zIndex: 100,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    gap: 20,

    // '@media (min-width: 768px)': {
    //   flexDirection: 'row',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    //   gap: 50,
    // },
  },

  cta: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.7,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.red,

    '@media (min-width: 768px)': {
      fontSize: 20,
    },
  },

  bottomImagePosition: {
    position: 'absolute',
    bottom: 0,
    '@media (max-width: 767px)': {
      // width: 1100,
      // height: 300,
      minWidth: 1020,
      objectPosition: 'center',
      imagePosition: 'center',
    },
    '@media (min-width: 768px) and (max-width: 1023px)': {
      minWidth: 1020,

      objectPosition: 'center',
      objectGit: 'cover',
      imagePosition: 'center',
    },

    '@media (min-width: 1024px)': {
      maxWidth: 1600,
      objectPosition: 'center',
      imagePosition: 'center',
    },
  },

  containerGreyBottom: {
    backgroundColor: themeColors.backgound,
    height: 110,
    position: 'relative',
    '@media (min-width: 768px)': {
      height: 110,
    },
    overflowX: 'clip',
    // overflowY: 'initial',
  },

  containerDarkBottom: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 200,
    position: 'relative',
    '@media (min-width: 768px)': {
      paddingTop: 160,
    },
  },
  redTitleBox: {
    paddingTop: '25vw',
    '@media (min-width: 1024px)': {
      paddingTop: 250,
    },
  },

  price: {
    // fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'right',
  },

  priceDescription: {
    // maxWidth: '70%',
  },

  priceSign: {
    fontSize: 14,
    //extAlign: 'right',
    whiteSpace: 'nowrap',
  },

  input: {
    backgroundColor: '#FAFAFA',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    paddingBottom: 15,
    borderRadius: 10,
    border: '1px solid #F0F0F0',
    color: '#F6F6F6',
    fontSize: 14,
    minHeight: 55,
  },

  inputArea: {
    backgroundColor: '#FAFAFA',
    padding: 15,
    borderRadius: 10,
    border: '1px solid #F0F0F0',
    color: '#F6F6F6',
    height: 190,
    fontSize: 14,
  },

  greenButton: {
    fontSize: 14,
    fontWeight: 'bold',
    color: themeColors.white,
    backgroundColor: '#23B33A',
    paddingLeft: 50,
    paddingRight: 50,
    // paddingTop: 30,/
    // paddingBottom: 30,
    height: 70,
  },

  leftIcon: {
    marginLeft: 0,
  },

  gridSection: {
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, auto) minmax(min-content, auto)',
    gridTemplateRows: 'auto',

    justifyContent: 'space-between',
    alignItems: 'start',

    gap: 20,
    // border: '1px solid black',

    '@media (max-width: 425px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      gap: 0,
    },
  },

  titleSection: {
    '@media (max-width: 425px)': {
      textAlign: 'center',
    },
  },

  contentSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    width: '100%',
    alignItems: 'flex-end',

    '@media (max-width: 425px)': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 10,
    },
  },
}));
