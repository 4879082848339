import {
  createStyles,
  Title,
  Text,
  Button,
  Center,
  Space,
  Anchor,
  Image,
  Box,
  Stack,
  BackgroundImage,
  Modal,
  UnstyledButton,
  Menu,
  Group,
  Divider,
  Paper,
  Badge,
} from '@mantine/core';
import { ElfsightWidget } from 'react-elfsight-widget';
import DistributorLocations from './DistributorLocations';
import AppLogo from '../components/AppLogo';
import { ReactComponent as SayHelpLogoWhite } from '../assets/sayhelp-logo-white.svg';
import sayHelpLogoWhite from '../assets/sayhelp-logo-white.svg';
import { ReactComponent as PlayStoreBadge } from '../assets/playStoreBadge.svg';
import { ReactComponent as AppStoreBadge } from '../assets/appStoreBadge.svg';

import { ReactComponent as TwitterIcon } from '../assets/X_icon.svg';
import { ReactComponent as FacebookIcon } from '../assets/facebookIcon.svg';

// Photos
import sayhelpAppPreview from '../assets/sayhelpAppPreview.png';
import sayhelpAppPreviewWebp from '../assets/sayhelpAppPreview.webp';

import landingPhotoOne from '../assets/landing-photo-1.png';
import landingPhotoTwo from '../assets/landing-photo-2.png';
import landingPhotoThree from '../assets/landing-photo-3.png';

import appPreview1 from '../assets/app-preview@2x.jpg';
import appPreview2 from '../assets/app-preview-2@2x.jpg';
import advantagesBg from '../assets/advantages-bg.jpg';

import phonePreview1 from '../assets/phone-preview-1.jpg';
import phonePreview2 from '../assets/phone-preview-2.jpg';

import { themeColors } from '../config/themeSettings';
import { Link, Route, Routes, useSearchParams } from 'react-router-dom';
import { useState } from 'react';

import AboutContent from './AboutContent';
import ComingSoonText from './components/ComingSoonText';

const LandingContent = ({ title }) => {
  const { classes } = useStyles();

  let PageContent = <></>;

  const [appStoreModal, setAppStoreModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const menuPage = searchParams.get('page') ? searchParams.get('page') : 'home';

  if (menuPage === 'about') PageContent = <AboutContent />;

  return (
    <div>
      <section className={classes.header}>
        <Title order={2} className={classes.title}>
          Say Help - personal safety app
        </Title>
        <Text size="md" py={15} className={classes.subtitle}>
          Say “Help Help Help” will alert friends and family through your
          profile with exact location and real-time video from your mobile
          phone.
        </Text>
        {/* <Button
                color="red"
                radius="md"
                size="lg"
                uppercase
                rightIcon={<IconArrowUpRight />}
                styles={{
                  root: classes.redbutton,
                  rightIcon: classes.rightIcon,
                }}>
                Get Started
              </Button> */}
      </section>
      <section>
        <div className={classes.space} />
      </section>

      <div className={classes.containerDark}>
        <div style={{ position: 'relative' }}>
          <div className={classes.positionUp}>
            <Image
              src={sayhelpAppPreviewWebp}
              className={classes.appPreview}
              style={{ width: '80%', height: 'auto' }}
            />
          </div>
        </div>
        <div className={classes.flex}>
          <div className={classes.containerGrey}>
            <section className={classes.darksectionMiddle}>
              <Box className={classes.redTitleBox}>
                {/* <Title order={3} className={classes.redTitle}>
                  Meet Say Help
                </Title> */}
              </Box>
              <Title
                order={1}
                className={classes.titleWhite}
                align="center"
                style={{ maxWidth: 900 }}>
                Critical information is available to your family and friends to
                act on your behalf.
              </Title>

              <Box pt={30}>
                <div className={classes.photosFlex}>
                  <Image src={landingPhotoOne} width={170} />
                  <Image src={landingPhotoTwo} width={260} />
                  <Image src={landingPhotoThree} width={170} />
                </div>
              </Box>
            </section>
          </div>
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.contentflex}>
          <div className={classes.phonePreview}>
            <Image
              src={phonePreview1}
              withPlaceholder
              className={classes.phonePreviewImages}
            />
            <Image
              src={phonePreview2}
              pt={70}
              withPlaceholder
              className={classes.phonePreviewImages}
            />
          </div>
          <Stack spacing={20} className={classes.phonePreviewDescription}>
            <Title order={3} className={classes.titleRedLine}>
              Having an emergency, we rely on friends and family for help.
            </Title>
            <Text className={classes.description}>
              Alerting your friends is easy, just press a button or say “Help
              Help Help”. Additionally, you have the option to alert friends
              with 5 customized words or phrases that you create.
            </Text>
          </Stack>

          {/*  <div className={classes.phonePreview}>
            <Image src={appPreview1} className={classes.appPreviewImages} />
          </div>
         <Stack spacing={20} className={classes.phonePreviewDescriptionTablet}>
            <Group position="apart" spacing={10}>
              <Title order={3} className={classes.titleRedLine}>
                Professional dispatchers
              </Title>
              <ComingSoonText />
            </Group>

            <Text className={classes.description}>
              Dispatcher monitor the location of an accident through a video or
              an audio connection. The dispatcher can also get and pass more
              critical information about the place of the event.
            </Text>
          </Stack> */}
        </div>
        <div className={classes.contentflexReverse}>
          <div style={{ flex: 1 }}>
            <Image src={appPreview2} className={classes.appPreviewImages} />
          </div>
          <div className={classes.flexReverse}>
            <Stack
              spacing={20}
              className={classes.phonePreviewDescriptionTablet}
              align="flex-start">
              <Title order={3} className={classes.titleRedLine}>
                Detailed information is provided to your emergency contacts
              </Title>
              <Text className={classes.description} style={{ fontSize: 16 }}>
                Your location, profile data and streaming audio-video is
                automatically shared with friends and family. “Say Help” aims at
                simplifying and speeding up the process of calling for help.
              </Text>
            </Stack>
          </div>
        </div>
      </div>

      <div className={classes.containerDarkImage}>
        <Box sx={{ maxWidth: '100%' }} mx="auto">
          <BackgroundImage src={advantagesBg}>
            <div className={classes.advFlex}>
              {/* <Box pt={50}>
                  <Title order={4} className={classes.redTitle}>
           
                  </Title>
                </Box> */}
              {/* <Title order={1} className={classes.titleWhite} align="center">
                 
                </Title> */}

              <div className={classes.advantagesFlex}>
                <div className={classes.advantagesDescriptionFlex}>
                  {/* <Title order={3} className={classes.titleOutline}>
                    01/
                  </Title> */}
                  <Title order={3} className={classes.titleWhite}>
                    Voice-triggered
                  </Title>
                  <Text className={classes.descriptionWhite}>
                    Alert your friends even when your phone is in your pocket or
                    your bag.
                  </Text>
                </div>

                <div className={classes.advantagesDescriptionFlex}>
                  {/* <Title order={3} className={classes.titleOutline}>
                    02/
                  </Title> */}
                  <Title order={3} className={classes.titleWhite}>
                    Timing matters
                  </Title>
                  <Text className={classes.descriptionWhite}>
                    Alert your emergency contacts with real-time data.
                  </Text>
                </div>

                {/* <div className={classes.advantagesDescriptionFlex}>
                  {/* <Title order={3} className={classes.titleOutline}>
                      03/
                    </Title> */}
                {/*  <Title order={3} className={classes.titleWhite}>
                    Situational clarity
                  </Title>
                  <Text className={classes.descriptionWhite}>
                    Real-time data (GPS, audio, video) for operators.
                  </Text>
                </div> */}
              </div>
            </div>
          </BackgroundImage>
        </Box>
        <br></br>
        <Title order={2} className={classes.title}>
          Distributor and Partner Locations
        </Title>
        <br></br>
        <div className={classes.map}>
          <DistributorLocations />
        </div>
        <br></br>
      </div>
    </div>
  );
};

export default LandingContent;

const useStyles = createStyles(theme => ({
  map: {
    //marginLeft: 'auto',
    //paddingRight: 20,
    margin: '0  auto',
    width: '40%',

    '@media (max-width: 425px)': {
      width: '95%',
    },

    '@media (min-width: 426px)': {
      width: '75%',
    },

    '@media (min-width: 1023px)': {
      width: '75%',
    },
  },
  logoSlider: {
    //marginLeft: 'auto',
    //paddingRight: 20,
    margin: '0  auto',
    width: '40%',

    '@media (max-width: 425px)': {
      width: '90%',
    },

    '@media (min-width: 426px)': {
      width: '75%',
    },

    '@media (min-width: 1023px)': {
      width: '75%',
    },
  },

  root: {
    backgroundColor: themeColors.white,
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
  },

  container: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },

  containerDark: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,

    position: 'relative',
  },

  flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',

    // General Gap between header buttons title and elements
    gap: 20,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    gridTemplateRows: 'auto',
    justifyContent: 'space-between',

    '@media (max-width: 425px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      justifyContent: 'center',
      alignContent: 'center',
      justifyItems: 'center',
    },
    '@media (max-width: 1000px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      justifyContent: 'center',
      alignContent: 'center',
      justifyItems: 'center',
      gap: 20,
    },
  },
  terms: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    // alignItems: 'center',
    justifyContent: 'center',

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 50,
    },

    '@media (max-width: 425px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 0,
    },
  },

  menuFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  menu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // alignItems: 'center',
    justifyContent: 'center',
    gpa: 10,
    borderRadius: 6,
    backgroundColor: themeColors.menu,
    height: 40,

    '@media (max-width: 550px)': {
      display: 'none',
    },
  },

  menuLink: {
    color: '#D6D6D6',

    paddingLeft: 34,
    paddingRight: 34,

    borderRight: '1px solid #D8D8D8',

    ':last-child': {
      borderRight: 'none',
    },

    webkitTransition: 'opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    transition: 'opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    outlineOffset: '-7px',
  },

  menuLinkActive: {
    color: themeColors.white,
    // color: 'dodgerblue',
    fontWeight: 'normal',
    paddingLeft: 34,
    paddingRight: 34,

    WebkitTapHighlightColor: 'rgba(0,0,0,0)',

    borderRight: '1px solid #D8D8D8',

    ':last-child': {
      borderRight: 'none',
    },

    '@media (max-width: 425px)': {
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: 16,
    },

    webkitTransition: 'opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    transition: 'opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
  },

  badgesTop: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // alignItems: 'center',
    justifyContent: 'center',
    gpa: 20,

    '@media (max-width: 425px)': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 20,
    },

    '@media (min-width: 426px)': {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 20,
    },

    '@media (min-width: 1023px)': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 20,
    },
  },

  anchor: {
    color: themeColors.anchor,
    fontSize: 14,
  },
  badges: {
    zIndex: 100,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 20,
  },

  apppreview: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 20,
  },

  apppreviewPosition: {
    // position: 'absolute',
    // top: 0,
  },

  header: {
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'center',
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 900px)',
    gridTemplateRows: 'auto',
    justifyContent: 'center',
    justifyItems: 'center',
    gap: 0,
    // gridColumn:

    // maxWidth: 1000,
  },

  title: {
    fontFamily: 'Poppins',
    fontSize: '1.5em',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.anchor,

    '@media (min-width: 768px) and (max-width: 1023px) ': {
      fontSize: '1.8em',
    },
    '@media (min-width: 1024px)': {
      fontSize: '2.1em',
    },

    '@media (max-width: 425px)': {
      fontSize: '1.3rem',
    },
    '@media (max-width: 374px)': {
      fontSize: '1rem',
    },
  },

  titleWhite: {
    fontFamily: 'Poppins',
    fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    // maxWidth: 900,
    textAlign: 'center',
    color: themeColors.white,

    '@media (min-width: 768px) and (max-width: 1023px) ': {
      fontSize: 30,
    },

    '@media (min-width: 1024px) ': {
      fontSize: 32,
    },
  },
  subtitle: {
    fontFamily: 'Poppins',
    // fontSize: 13,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.15,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.anchor,

    '@media (min-width: 768px) ': {
      maxWidth: '80%',
      fontSize: '1rem',
    },

    '@media (min-width: 1024px) ': {
      maxWidth: '80%',
      fontSize: '1.2rem',
    },
  },

  redTitle: {
    fontFamily: 'Poppins',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    letterSpacing: 4,
    textAlign: 'center',
    color: '#ec1d23',
    textTransform: 'uppercase',

    '@media (min-width: 768px)': {
      fontSize: '1.2rem',
    },
  },

  ctaTitle: {
    fontFamily: 'Poppins',
    fontSize: '0.85rem',
    fontWeight: 'bold',
    letterSpacing: 1,
    textAlign: 'center',
    // color: '#ec1d23',
    textTransform: 'uppercase',
  },

  redbutton: {
    // updated fonts
    fontSize: 14,
    fontWeight: 'bold',
    color: themeColors.white,
    backgroundColor: '#EC1D23',
    width: 250,
  },

  rightIcon: {
    marginLeft: 0,
  },

  containerGrey: {
    backgroundColor: themeColors.backgound,
    // height: 900,
    // minHeight: 400,
  },

  darksectionMiddle: {
    paddingLeft: 20,
    paddingRight: 20,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,

    '@media (max-width: 767px) ': {
      paddingBottom: 60,
    },

    '@media (min-width: 768px) and (max-width: 1023px)': {
      paddingBottom: 80,
    },
    '@media (min-width: 1024px) ': {
      paddingBottom: 80,
    },
  },

  darksection: {
    paddingLeft: 20,
    paddingRight: 20,
  },

  positionUp: {
    width: '100%',
    position: 'absolute',
    bottom: '-20vw',
    // top: -330,
    left: 0,
    right: 0,
    // margin: '0 auto',

    '@media (min-width: 1024px)': {
      bottom: '-210px',
    },
    // '@media (min-width: 768px) and (max-width: 1023px)': {
    //   top: '-38vw',
    // },
    // '@media (max-width: 767px)': {
    //   top: '-38vw',
    // },
    // '@media (min-width: 1024px)': {
    //   top: '-310px',
    //   // top: '-330px',
    //   // left: '20%',
    //   // right: '20%',
    // },
    // position: 'absolute',
    // top: -100,
    // '@media (min-width: 768px) and (max-width: 1023px)': {
    //   transform: 'translate(0,-33vw)',
    // },
    // '@media (max-width: 767px)': {
    //   transform: 'translate(0,-33vw)',
    // },
    // '@media (min-width: 1024px)': {
    //   transform: 'translate(0,-337px)',
    // },
  },

  appPreview: {
    maxWidth: 854,
    width: '80%',
    height: 'auto',
    margin: '0 auto',

    '@media (min-width: 768px) and (max-width: 1023px)': {
      height: 'auto',
      margin: '0 auto',
      width: '80%',
    },
    '@media (max-width: 767px) ': {
      height: 'auto',
      margin: '0 auto',
      width: '80%',
    },
  },

  space: {
    // height: 1215,
    // minHeight: 400,
    paddingBottom: '45vw',

    '@media (min-width: 1024px)': {
      paddingBottom: '450px',
    },
  },

  photosFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: 20,

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      alignItems: 'flex-end',
      gap: 50,
      justifyContent: 'space-around',
      // maxWidth: 900,
    },
  },

  contentflex: {
    // paddingTop: 30,
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 1fr) minmax(min-content, 1fr)',
    gridTemplateRows: 'auto',

    justifyContent: 'space-evenly',

    gap: 20,

    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
    },
  },

  contentflexReverse: {
    paddingTop: 50,
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',

    gap: 20,

    '@media (max-width: 767px)': {
      flexDirection: 'column',
    },
  },

  titleRedLine: {
    // fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.88,
    letterSpacing: 'normal',
    color: themeColors.text,

    borderLeft: '6px solid #EC1D23',
    paddingLeft: 20,
  },

  description: {
    // fontSize: 13,
    fontWeight: 'normal',
    lineHeight: 2.15,
    letterSpacing: 'normal',
    paddingLeft: 30,
    paddingRight: 30,
  },

  containerDarkImage: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 20,
    paddingBottom: 20,

    '@media (min-width: 1024px)': {
      // paddingTop: 100,
      // paddingBottom: 100,
    },
  },

  titleOutline: {
    fontFamily: 'Poppins',
    WebkitTextStroke: '1px #ec1c24',
    fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: themeColors.text,
  },

  advantagesFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    justifyItems: 'center',

    gap: 20,

    '@media (max-width: 768px)': {
      paddingTop: 20,
    },

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },

  descriptionWhite: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.8,
    letterSpacing: 'normal',
    textAlign: 'center',
    maxWidth: '80%',

    color: themeColors.white,
    '@media (min-width: 375px)': {
      fontSize: 16,
    },
    '@media (min-width: 768px)': {
      fontSize: 16,
      lineHeight: 1.8,
      maxWidth: '90%',
    },
    '@media (min-width: 1024px)': {
      fontSize: 18,
      maxWidth: '70%',
    },
  },

  advantagesDescriptionFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    // maxWidth: '33.3333333333%',
    // flexBasis: '33.3333333333%',
    flexBasis: '50%',
    '@media (min-width: 768px)': {
      alignItems: 'center',
    },
  },

  advFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 50,
    paddingBottom: 50,
    paddingLeft: 20,
    paddingRight: 20,

    '@media (min-width: 768px)': {
      paddingLeft: 30,
      paddingRight: 30,
    },
    '@media (min-width: 1024px)': {
      paddingTop: 100,
      paddingBottom: 100,
    },
  },

  phonePreview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 20,

    '@media (min-width: 768px)': {
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
  },

  phonePreviewImages: {
    maxWidth: 150,
    width: '80%',
    // height: 'auto',
    // margin: '0 auto',
  },

  phonePreviewDescriptionTablet: {
    paddingTop: 20,

    '@media (min-width: 1024px)': {
      paddingTop: 50,
      paddingBottom: 100,
      maxWidth: 450,
    },
  },

  phonePreviewDescription: {
    paddingTop: 20,

    '@media (min-width: 768px)': {
      paddingTop: 90,
      // paddingBottom: 130,
      maxWidth: 450,
    },
  },

  appPreviewImages: {
    maxWidth: 550,
    width: '100%',
  },

  flexReverse: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },

  flexBottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    // General Gap between header buttons title and elements
    gap: 20,

    paddingLeft: 50,
    paddingRight: 50,
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
  },

  flexBottomLogo: {
    alignItems: 'center',
    '@media (min-width: 768px)': {
      alignItems: 'flex-start',
    },
  },

  termsBottom: {
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // alignItems: 'center',
    justifyContent: 'space-between',

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 50,
    },
  },

  termsSocial: {
    zIndex: 100,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    gap: 20,

    // '@media (min-width: 768px)': {
    //   flexDirection: 'row',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    //   gap: 50,
    // },
  },

  cta: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.7,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.red,

    '@media (min-width: 768px)': {
      fontSize: 20,
    },
  },

  bottomImagePosition: {
    position: 'absolute',
    bottom: 0,
    '@media (max-width: 767px)': {
      // width: 1100,
      // height: 300,
      minWidth: 1020,
      objectPosition: 'center',
      imagePosition: 'center',
    },
    '@media (min-width: 768px) and (max-width: 1023px)': {
      minWidth: 1020,

      objectPosition: 'center',
      objectGit: 'cover',
      imagePosition: 'center',
    },

    '@media (min-width: 1024px)': {
      maxWidth: 1600,
      objectPosition: 'center',
      imagePosition: 'center',
    },
  },

  containerGreyBottom: {
    backgroundColor: themeColors.backgound,
    height: 110,
    position: 'relative',
    '@media (min-width: 768px)': {
      height: 110,
    },
    overflowX: 'clip',
    // overflowY: 'initial',
  },

  containerDarkBottom: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 200,
    position: 'relative',
    '@media (min-width: 768px)': {
      paddingTop: 160,
    },
  },
  redTitleBox: {
    paddingTop: '25vw',
    '@media (min-width: 1024px)': {
      paddingTop: 250,
    },
  },
}));
