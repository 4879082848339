import {
  createStyles,
  Title,
  Text,
  Button,
  Center,
  Space,
  Anchor,
  Image,
  Box,
  Stack,
  BackgroundImage,
  Modal,
  UnstyledButton,
  Menu,
  Group,
  Divider,
  List,
  TextInput,
  Textarea,
  ActionIcon,
  Notification,
  Loader,
  Table,
  Container,
  Grid,
  Accordion,
} from '@mantine/core';
import { ElfsightWidget } from 'react-elfsight-widget';
import { themeColors } from '../config/themeSettings';
import { Link, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { IconArrowUpRight, IconCheck, IconX } from '@tabler/icons';

import { useForm } from 'react-hook-form';

import { useFormspark } from '@formspark/use-formspark';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import ComingSoonText from './components/ComingSoonText';
import ComparisonChart from './components/ComparisonChart';

import image from '../pages/assets/faq.svg';

const FORMSPARK_FORM_ID = '5IWk8E6J';

let schema = yup.object({
  Name: yup.string().min(2).max(20).label('Name').required(),
  Email: yup.string().email().label('Email').required(),
  Message: yup.string().min(2).label('Message').required(),
});

const PricingContent = () => {
  const { classes } = useStyles();

  const placeholder =
    'It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.';

  // Success Notification after sending email
  const [emailSuccess, setEmailSuccess] = useState(false);

  // Fail Notification after sending email
  const [emailError, setEmailError] = useState(false);

  // Loader Spinner when Email starts sending
  const [emailSending, setEmailSending] = useState(false);

  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // onSubmit via firebase function and gmail smtp service
  // Limits: 100 per day
  // const onSubmit = data => {
  //   setEmailSending(true);
  //   const functions = getFunctions();

  //   // Firebase Functions Emulator - uncomment to enable
  //   // connectFunctionsEmulator(functions, 'localhost', 5001);

  //   const sendEmail = httpsCallable(functions, 'sendEmail');
  //   sendEmail(data)
  //     .then(result => {
  //       setEmailSuccess(true);
  //       setEmailError(false);
  //       setEmailSending(false);
  //       reset();
  //     })
  //     .catch(error => {
  //       console.error(error);
  //       setEmailSuccess(false);
  //       setEmailError(true);
  //       setEmailSending(false);
  //     });
  // };

  const onSubmit = data => {
    if (!data) return;

    const { Name, Email, Message } = data;
    const submitData = { From: Name, Email: Email, Message: Message };

    setEmailSending(true);

    submit(submitData)
      .then(result => {
        setEmailSuccess(true);
        setEmailError(false);
        setEmailSending(false);
        reset();
      })
      .catch(error => {
        console.error(error);
        setEmailSuccess(false);
        setEmailError(true);
        setEmailSending(false);
      });
  };

  const faqs = [
    {
      value:
        'Do I have to provide access to my microphone, camera, and device location?',
      description:
        'Yes. In order for Say Help to function, we require access to the device\'s microphone, camera, and location permissions. The microphone is only accessed if you enable "Listening Mode" or once you initiate an alert. The camera and location is not accessed until an alert is initiated.',
    },
    {
      value:
        'Does Say Help Application still send Alerts out and video if Say Help is in the background?',
      description:
        'On Android devices, all app features will work as if the app is open. However, on IOS devices, video can be frozen until the application is displayed as the primary application on the phone.',
    },
    {
      value: 'Can Say Help be used on different mobile devices?  ',
      description:
        'Yes. Say Help is available on iOS or Android. Android phones have various manufacturing companies, therefore, to use Say Help, Android phones must have Google play and google services available on their devices to download and utilize Say Help functionality. ',
    },
    {
      value:
        'How many Friends and Family members can I have as emergency contacts?',
      description: 'Up to Five Emergency Contacts are allowed. ',
    },
    {
      value: 'What message types are sent out via an alert?',
      description:
        'Two types of messages a user can select per emergency contact (Short Message Service/SMS (Normal Texting) and WhatsApp). ',
    },
    {
      value:
        'Do I need to inform my Friends and Family members before I enter their information in the application as my emergency contracts? ',
      description:
        'Once you add an emergency contact in the US, a text is sent to them on behalf of Say Help requesting permission for Say Help to message them whenever you raise an alert. ',
    },
    {
      value: 'Can I utilize Say Help on other devices?',
      description:
        'Yes, Say Help can be utilized on Tablets and IPADs. Functionality can depend upon specific versions.',
    },
    {
      value: 'How many friends can I show the application capability?',
      description:
        'Three. This is via “Show a Friend” capability in the application. ',
    },
    {
      value:
        'Are there other hidden or additional costs outside the monthly subscription fee to use the Say Help Application?',
      description: 'No',
    },
    {
      value: 'How many trigger phrases can I have?',
      description:
        'You are allowed up to 5 custom trigger phrases in addition to the default "Help Help Help" trigger phrase',
    },
    {
      value:
        'What happens when I link a specific emergency contact but not the other?',
      description:
        'Say Help will only send the alert details to the specified contact. If you have other contacts that are not linked to the trigger phrase, they will not receive the alert details.',
    },
  ];
  const [activeItem, setActiveItem] = useState('');

  const items = faqs.map(item => (
    <Accordion.Item key={item.value} value={item.value}>
      <Accordion.Control>{item.value}</Accordion.Control>
      <Accordion.Panel>{item.description}</Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <div //style={{ backgroundImage: `url(${image})` }}
    >
      {/* <Image src={image} /> */}

      <section className={classes.header}>
        <Title order={2} className={classes.title}>
          Frequently Asked Questions
        </Title>
      </section>
      <div className={classes.container}>
        <Box className={classes.sectionCenter}>
          <Box>
            <Accordion
              styles={{
                label: {
                  fontWeight: 'bold',
                },
              }}
              defaultValue="Do I have to provide access to my microphone, camera, and device location?"
              variant="separated">
              {items}
            </Accordion>
          </Box>
          <Stack mt={100}>
            <Stack align="center">
              <Title order={1} className={classes.title}>
                Contact Us
              </Title>
              <Text size="lg">Please provide information below</Text>
            </Stack>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack align="left" spacing={20}>
                <TextInput
                  placeholder="Name*"
                  className={classes.input}
                  variant="unstyled"
                  {...register('Name', {
                    required: true,
                    minLength: 2,
                    maxLength: 20,
                  })}
                />
                {errors.Name && (
                  <Text weight={700} color="red" pl={15}>
                    {errors.Name?.message}
                  </Text>
                )}

                <TextInput
                  placeholder="Email*"
                  className={classes.input}
                  withAsterisk
                  variant="unstyled"
                  {...register('Email')}
                />
                {errors.Email && (
                  <Text weight={700} color="red" pl={15}>
                    {errors.Email?.message}
                  </Text>
                )}

                <Textarea
                  placeholder="Message*"
                  color="#FAFAFA"
                  variant="unstyled"
                  minRows={6}
                  maxRows={8}
                  className={classes.inputArea}
                  {...register('Message')}
                />
                {errors.Message && (
                  <Text weight={700} color="red" pl={15}>
                    {errors.Message?.message}
                  </Text>
                )}
                <Stack align="center" mt={30}>
                  {emailSending && (
                    <Stack align="center">
                      <Loader />
                      <Text>Sending...</Text>
                    </Stack>
                  )}
                  {emailSuccess && (
                    <Notification
                      icon={<IconCheck size={18} />}
                      color="teal"
                      onClose={() => setEmailSuccess(false)}>
                      <Text size="md">
                        Your message has been sent successfuly
                      </Text>
                    </Notification>
                  )}
                  {emailError && (
                    <Notification
                      icon={<IconX size={18} />}
                      color="red"
                      onClose={() => setEmailError(false)}>
                      <Text size="md">Your message has not been sent</Text>
                    </Notification>
                  )}
                </Stack>
                <Center mt={30}>
                  <Button
                    // disabled={emailSending}
                    loading={emailSending}
                    type="submit"
                    color="dark"
                    radius="md"
                    size="lg"
                    uppercase
                    rightIcon={<IconArrowUpRight />}
                    styles={{
                      root: classes.redbutton,
                      rightIcon: classes.rightIcon,
                    }}>
                    Submit Inquiry
                  </Button>
                </Center>

                {/* <Center mt={60}>
                  <Button
                    leftIcon={<WhatsappLogo />}
                    color="green"
                    radius="xl"
                    size="xl"
                    styles={{
                      root: classes.greenButton,
                      leftIcon: classes.leftIcon,
                    }}
                    onClick={() => console.log('WhatsApp Message')}>
                    <Text weight={700}>Message us on WhatsApp</Text>
                  </Button>
                </Center> */}
              </Stack>
            </form>
          </Stack>
        </Box>
      </div>
    </div>
  );
};

export default PricingContent;

const useStyles = createStyles(theme => ({
  root: {
    fontFamily: 'Poppins',
    backgroundColor: themeColors.white,
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
  },

  differences: {
    width: '100%',
  },

  wrapper: {
    paddingTop: `calc(${theme.spacing.xl}px * 2)`,
    paddingBottom: `calc(${theme.spacing.xl}px * 2)`,
  },

  item: {
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
  },

  container: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },

  containerDark: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,

    position: 'relative',
  },

  flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',

    // General Gap between header buttons title and elements
    gap: 20,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    gridTemplateRows: 'auto',
    justifyContent: 'space-between',

    '@media (max-width: 425px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      justifyContent: 'center',
      alignContent: 'center',
      justifyItems: 'center',
    },
    '@media (max-width: 1000px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      justifyContent: 'center',
      alignContent: 'center',
      justifyItems: 'center',
      gap: 20,
    },
  },
  terms: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    // alignItems: 'center',
    justifyContent: 'center',

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 50,
    },

    '@media (max-width: 425px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 0,
    },
  },

  menuFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  menu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // alignItems: 'center',
    justifyContent: 'center',
    gpa: 10,
    borderRadius: 6,
    backgroundColor: themeColors.menu,
    height: 40,

    '@media (max-width: 550px)': {
      display: 'none',
    },
  },

  menuLink: {
    color: themeColors.white,
    paddingLeft: 34,
    paddingRight: 34,

    borderRight: '1px solid #D8D8D8',

    ':last-child': {
      borderRight: 'none',
    },
  },

  menuLinkActive: {
    color: themeColors.white,
    fontWeight: 'bold',
    paddingLeft: 34,
    paddingRight: 34,

    borderRight: '1px solid #D8D8D8',

    ':last-child': {
      borderRight: 'none',
    },

    '@media (max-width: 425px)': {
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: 16,
    },
  },

  badgesTop: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // alignItems: 'center',
    justifyContent: 'center',
    gpa: 20,

    '@media (max-width: 425px)': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 20,
    },

    '@media (min-width: 426px)': {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 20,
    },

    '@media (min-width: 1023px)': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 20,
    },
  },

  anchor: {
    color: themeColors.anchor,
    fontSize: 14,
  },
  badges: {
    zIndex: 100,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 20,
  },

  apppreview: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 20,
  },

  apppreviewPosition: {
    // position: 'absolute',
    // top: 0,
  },

  header: {
    textAlign: 'center',
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 900px)',
    gridTemplateRows: 'auto',
    justifyContent: 'center',
    justifyItems: 'center',
    gap: 20,
    // gridColumn:

    // maxWidth: 1000,
  },

  title: {
    fontFamily: 'Poppins',
    // fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.anchor,
  },

  titleWhite: {
    fontFamily: 'Poppins',
    fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    // maxWidth: 900,
    textAlign: 'center',
    color: themeColors.white,

    '@media (min-width: 768px) and (max-width: 1023px) ': {
      fontSize: 20,
    },
    '@media (max-width: 767px)': {
      fontSize: 27,
    },
  },
  subtitle: {
    fontFamily: 'Poppins',
    // fontSize: 13,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.15,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.anchor,
  },

  redTitle: {
    fontFamily: 'Poppins',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    letterSpacing: 4,
    textAlign: 'center',
    color: '#ec1d23',
    textTransform: 'uppercase',
  },

  ctaTitle: {
    fontFamily: 'Poppins',
    fontSize: '0.85rem',
    fontWeight: 'bold',
    letterSpacing: 1,
    textAlign: 'center',
    // color: '#ec1d23',
    textTransform: 'uppercase',
  },

  redbutton: {
    // updated fonts
    fontSize: 14,
    fontWeight: 'bold',
    color: themeColors.white,
    backgroundColor: '#1E1E1E',
    width: 250,
  },

  rightIcon: {
    marginLeft: 0,
  },

  containerGrey: {
    backgroundColor: themeColors.backgound,
    // height: 900,
    // minHeight: 400,
  },

  darksectionMiddle: {
    paddingLeft: 20,
    paddingRight: 20,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,

    '@media (max-width: 767px) ': {
      paddingBottom: 60,
    },

    '@media (min-width: 768px) and (max-width: 1023px)': {
      paddingBottom: 80,
    },
    '@media (min-width: 1024px) ': {
      paddingBottom: 80,
    },
  },

  darksection: {
    paddingLeft: 20,
    paddingRight: 20,
  },

  photosContent: {
    maxWidth: '33%',
    '@media (max-width: 768px)': {
      maxWidth: '390px',
    },
  },

  positionUp: {
    width: '100%',
    position: 'absolute',
    bottom: '-20vw',
    // top: -330,
    left: 0,
    right: 0,
    // margin: '0 auto',

    '@media (min-width: 1024px)': {
      bottom: '-210px',
    },
    // '@media (min-width: 768px) and (max-width: 1023px)': {
    //   top: '-38vw',
    // },
    // '@media (max-width: 767px)': {
    //   top: '-38vw',
    // },
    // '@media (min-width: 1024px)': {
    //   top: '-310px',
    //   // top: '-330px',
    //   // left: '20%',
    //   // right: '20%',
    // },
    // position: 'absolute',
    // top: -100,
    // '@media (min-width: 768px) and (max-width: 1023px)': {
    //   transform: 'translate(0,-33vw)',
    // },
    // '@media (max-width: 767px)': {
    //   transform: 'translate(0,-33vw)',
    // },
    // '@media (min-width: 1024px)': {
    //   transform: 'translate(0,-337px)',
    // },
  },

  appPreview: {
    maxWidth: 854,
    width: '80%',
    height: 'auto',
    margin: '0 auto',

    '@media (min-width: 768px) and (max-width: 1023px)': {
      height: 'auto',
      margin: '0 auto',
      width: '80%',
    },
    '@media (max-width: 767px) ': {
      height: 'auto',
      margin: '0 auto',
      width: '80%',
    },
  },

  space: {
    // height: 1215,
    // minHeight: 400,
    paddingBottom: '25vw',

    '@media (min-width: 1024px)': {
      paddingBottom: '150px',
    },
  },

  sectionCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '90vw',
    maxWidth: 900,
  },

  photosFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    flexBasis: '33.3333333333%',
    width: '100%',

    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },

  contentflex: {
    // paddingTop: 30,
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 1fr) minmax(min-content, 1fr)',
    gridTemplateRows: 'auto',

    justifyContent: 'space-evenly',

    gap: 20,

    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
    },
  },

  contentflexReverse: {
    paddingTop: 50,
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',

    gap: 20,

    '@media (max-width: 767px)': {
      flexDirection: 'column',
    },
  },

  titleRedLine: {
    // fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.88,
    letterSpacing: 'normal',
    color: themeColors.text,

    borderLeft: '6px solid #EC1D23',
    paddingLeft: 20,
  },

  description: {
    // fontSize: 13,
    fontWeight: 'normal',
    lineHeight: 2.15,
    letterSpacing: 'normal',
    paddingLeft: 30,
    paddingRight: 30,
  },

  containerDarkImage: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 20,
    paddingBottom: 20,

    '@media (min-width: 1024px)': {
      // paddingTop: 100,
      // paddingBottom: 100,
    },
  },

  titleOutline: {
    fontFamily: 'Poppins',
    WebkitTextStroke: '1px #ec1c24',
    fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: themeColors.text,
  },

  advantagesFlex: {
    // paddingTop: 50,

    // paddingLeft: 30,
    // paddingRight: 30,

    '@media (max-width: 768px)': {
      paddingTop: 20,
    },

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    gap: 20,
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },

  descriptionWhite: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: 'normal',

    color: themeColors.white,
    '@media (max-width: 767px)': {
      textAlign: 'center',
      fontSize: 16,
      lineHeight: 2.13,
    },
    '@media (min-width: 1023px)': {
      fontSize: 20,
    },
  },

  advantagesDescriptionFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 350,
    '@media (min-width: 768px)': {
      alignItems: 'flex-start',
    },
  },

  advFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 50,
    paddingBottom: 50,
    paddingLeft: 20,
    paddingRight: 20,

    '@media (min-width: 768px)': {
      paddingLeft: 30,
      paddingRight: 30,
    },
    '@media (min-width: 1024px)': {
      paddingTop: 100,
      paddingBottom: 100,
    },
  },

  phonePreview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 20,

    '@media (min-width: 768px)': {
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
  },

  phonePreviewImages: {
    maxWidth: 150,
    width: '80%',
    // height: 'auto',
    // margin: '0 auto',
  },

  phonePreviewDescriptionTablet: {
    paddingTop: 20,

    '@media (min-width: 1024px)': {
      paddingTop: 50,
      paddingBottom: 100,
      maxWidth: 450,
    },
  },

  phonePreviewDescription: {
    paddingTop: 20,

    '@media (min-width: 768px)': {
      paddingTop: 130,
      paddingBottom: 130,
      maxWidth: 350,
    },
  },

  appPreviewImages: {
    maxWidth: 550,
    width: '100%',
  },

  flexReverse: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },

  flexBottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    // General Gap between header buttons title and elements
    gap: 20,

    paddingLeft: 50,
    paddingRight: 50,
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
  },

  flexBottomLogo: {
    alignItems: 'center',
    '@media (min-width: 768px)': {
      alignItems: 'flex-start',
    },
  },

  termsBottom: {
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // alignItems: 'center',
    justifyContent: 'space-between',

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 50,
    },
  },

  termsSocial: {
    zIndex: 100,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    gap: 20,

    // '@media (min-width: 768px)': {
    //   flexDirection: 'row',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    //   gap: 50,
    // },
  },

  cta: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.7,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.red,

    '@media (min-width: 768px)': {
      fontSize: 20,
    },
  },

  bottomImagePosition: {
    position: 'absolute',
    bottom: 0,
    '@media (max-width: 767px)': {
      // width: 1100,
      // height: 300,
      minWidth: 1020,
      objectPosition: 'center',
      imagePosition: 'center',
    },
    '@media (min-width: 768px) and (max-width: 1023px)': {
      minWidth: 1020,

      objectPosition: 'center',
      objectGit: 'cover',
      imagePosition: 'center',
    },

    '@media (min-width: 1024px)': {
      maxWidth: 1600,
      objectPosition: 'center',
      imagePosition: 'center',
    },
  },

  containerGreyBottom: {
    backgroundColor: themeColors.backgound,
    height: 110,
    position: 'relative',
    '@media (min-width: 768px)': {
      height: 110,
    },
    overflowX: 'clip',
    // overflowY: 'initial',
  },

  containerDarkBottom: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 200,
    position: 'relative',
    '@media (min-width: 768px)': {
      paddingTop: 160,
    },
  },
  redTitleBox: {
    paddingTop: '25vw',
    '@media (min-width: 1024px)': {
      paddingTop: 250,
    },
  },

  price: {
    // fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'right',
  },

  priceDescription: {
    // maxWidth: '70%',
  },

  priceSign: {
    fontSize: 14,
    //extAlign: 'right',
    whiteSpace: 'nowrap',
  },

  input: {
    backgroundColor: '#FAFAFA',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    paddingBottom: 15,
    borderRadius: 10,
    border: '1px solid #F0F0F0',
    color: '#F6F6F6',
    fontSize: 14,
    minHeight: 55,
  },

  inputArea: {
    backgroundColor: '#FAFAFA',
    padding: 15,
    borderRadius: 10,
    border: '1px solid #F0F0F0',
    color: '#F6F6F6',
    height: 190,
    fontSize: 14,
  },

  greenButton: {
    fontSize: 14,
    fontWeight: 'bold',
    color: themeColors.white,
    backgroundColor: '#23B33A',
    paddingLeft: 50,
    paddingRight: 50,
    // paddingTop: 30,/
    // paddingBottom: 30,
    height: 70,
  },

  leftIcon: {
    marginLeft: 0,
  },

  gridSection: {
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, auto) minmax(min-content, auto)',
    gridTemplateRows: 'auto',

    justifyContent: 'space-between',
    alignItems: 'start',

    gap: 20,
    // border: '1px solid black',

    '@media (max-width: 425px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      gap: 0,
    },
  },

  titleSection: {
    '@media (max-width: 425px)': {
      textAlign: 'center',
    },
  },

  contentSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    width: '100%',
    alignItems: 'flex-end',

    '@media (max-width: 425px)': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 10,
    },
  },
}));
