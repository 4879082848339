const settings = {
  development: {
    maxParticipants: process.env.REACT_APP_MAXPARTICIPANTS,
    googleMapsApiKey: String(process.env.REACT_APP_GOOGLE_MAPS_API_KEY),
    generateAccessToken: String(
      process.env.REACT_APP_TWILIO_GENERATE_ACCESS_TOKEN
    ),
    firebaseConfig: {
      apiKey: process.env.REACT_APP_APIKEY,
      authDomain: process.env.REACT_APP_AUTHDOMAIN,
      databaseURL: process.env.REACT_APP_DATABASEURL,
      projectId: process.env.REACT_APP_PROJECTID,
      storageBucket: process.env.REACT_APP_STORAGEBUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
      experimentalAutoDetectLongPolling: true,
    },
  },
  production: {
    maxParticipants: process.env.REACT_APP_MAXPARTICIPANTS,
    googleMapsApiKey: String(process.env.REACT_APP_GOOGLE_MAPS_API_KEY),
    generateAccessToken: String(
      process.env.REACT_APP_TWILIO_GENERATE_ACCESS_TOKEN
    ),
    firebaseConfig: {
      apiKey: process.env.REACT_APP_APIKEY,
      authDomain: process.env.REACT_APP_AUTHDOMAIN,
      databaseURL: process.env.REACT_APP_DATABASEURL,
      projectId: process.env.REACT_APP_PROJECTID,
      storageBucket: process.env.REACT_APP_STORAGEBUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
      experimentalAutoDetectLongPolling: true,
    },
  },
};

const getCurrentSettings = () => {
  // if (process.env.NODE_ENV === 'development') return settings.development;
  if (process.env.NODE_ENV === 'production') return settings.production;

  return settings.development;
  // return settings.production;
};

export default getCurrentSettings();
