import React, { useState, useContext } from 'react';
import { TrafficContext } from './TrafficContext';
import {
    createStyles,
    Box,
    Space,
    Button,
    Title,
    Select,
} from '@mantine/core';
import { IconRefresh, IconMapSearch, IconRoad, IconTrain } from '@tabler/icons';
import ProfileSection from './ProfileSection';

import AppDivider from './AppDivider';


function MapSettingsData({ children, ...otherProps }) {
    const { classes } = useStyles();
    const { showTraffic, setShowTraffic } = useContext(TrafficContext);
    const { showTransit, setShowTransit } = useContext(TrafficContext);
    const { handleToggleSearchBox, searchBoxVisible } = useContext(TrafficContext);
    const { clearFilters } = useContext(TrafficContext);
    const { selectedState, setSelectedState, handleKmlLayerChange, getStatesWithKmlLayers } = useContext(TrafficContext);
    return (
        <Box className={classes.container} {...otherProps}>

            <ProfileSection>
                <Box>
                    <Title order={4} mt={32} mb={16} mr={10} ml={10} weight={300} color="white">Map Options</Title>

                    {/* Traffic Layer Button */}
                    <Button
                        className={classes.customButtonStyle}
                        sx={{ outline: showTraffic ? '1px solid red !important' : 'none' }}
                        leftIcon={<IconRoad size="2rem" />}
                        variant="transparent"
                        size="md"
                        onClick={() => {
                            setShowTraffic(!showTraffic);
                        }}
                    >
                        Traffic Layer
                    </Button>

                    {/* Transit Layer Button */}
                    <Button
                        className={classes.customButtonStyle}
                        sx={{ outline: showTransit ? '1px solid red !important' : 'none' }}
                        leftIcon={<IconTrain size="2rem" />}
                        variant="transparent"
                        size="md"
                        onClick={() => {
                            setShowTransit(!showTransit);
                        }}
                    >
                        Transit Layer
                    </Button>

                    {/* Map Search Button */}
                    <Button
                        className={classes.customButtonStyle}
                        sx={{ outline: searchBoxVisible ? '1px solid red !important' : 'none' }}
                        leftIcon={<IconMapSearch size="2rem" />}
                        variant="transparent"
                        size="md"
                        onClick={handleToggleSearchBox}
                    >
                        Map Search
                    </Button>

                    {/* Reset Filters Button */}
                    <Button
                        pt={0}
                        pl={7}
                        leftIcon={<IconRefresh size="2rem" />}
                        variant="transparent"
                        size="md"
                        onClick={clearFilters}
                    >
                        Reset Filters
                    </Button>

                    <Title order={4} mt={32} mb={16} mr={10} ml={10} weight={300} color="white">Emergency Services</Title>

                    {/* KML Layer Select */}
                    <Select
                        placeholder="Police Stations By State"
                        searchable
                        nothingFound="No Layer Found"
                        data={[
                            { label: "Default", value: "default" },
                            // { label: "Hurricane", value: "hurricane" },
                            // { label: "Earthquake", value: "earthquake" },
                            ...getStatesWithKmlLayers(),
                            // { label: "Weather", value: "xtreme" },
                        ]}
                        value={selectedState}
                        onChange={handleKmlLayerChange}
                        limit={4}
                        theme={{ colorScheme: 'dark' }}
                        styles={{
                            item: {
                                '&[data-selected]': {
                                    '&, &:hover': {
                                        backgroundColor: 'blue[4]',
                                        color: '#ffffff',
                                    },
                                },
                            },
                        }}
                    />

                    <Space h={10} w={10} />
                    <AppDivider />
                    <Space h={70} w={10} />
                </Box>
            </ProfileSection>
        </Box>
    );
}

export default MapSettingsData;

const useStyles = createStyles({
    container: {},
    scrollArea: {},

    customButtonStyle: {
        color: 'white',
        padding: '0 5px',
        width: '60%',
        fontWeight: '400',
    },

});



