import React from 'react';
import { Table, createStyles } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons';

const capabilities = [
  {
    name: 'Available in Both Play Stores (iOS and Google)',
    sayHelp: true,
    traditionalSafetyAlertApps: true,
  },
  {
    name: 'Automatic Family and Friend Notification via SMS',
    sayHelp: true,
    traditionalSafetyAlertApps: true,
  },
  {
    name: 'Automatic Family and Friend Notification via WhatsApp',
    sayHelp: true,
    traditionalSafetyAlertApps: false,
  },
  {
    name: 'Push Button Alerts',
    sayHelp: true,
    traditionalSafetyAlertApps: true,
  },
  {
    name: 'Voice Activation Alerts',
    sayHelp: true,
    traditionalSafetyAlertApps: false,
  },
  {
    name: 'Customized Voice Activation Trigger Phrases (up to 5)',
    sayHelp: true,
    traditionalSafetyAlertApps: false,
  },
  {
    name: 'Real-Time Video, Audio, and Location "Alert Room" for Family and Friends to Access',
    sayHelp: true,
    traditionalSafetyAlertApps: false,
  },
  {
    name: 'Health Status Check-in',
    sayHelp: true,
    traditionalSafetyAlertApps: false,
  },
  {
    name: '24/7 Dispatcher Capabilities (QTR 1 2024)',
    sayHelp: true,
    traditionalSafetyAlertApps: false,
  },
  {
    name: 'Car Crash Alerts',
    sayHelp: false,
    traditionalSafetyAlertApps: true,
  },
  {
    name: 'Cancel an Alert via a 4-Digit Pin or Passcode.',
    sayHelp: false,
    traditionalSafetyAlertApps: true,
  },
  {
    name: '24/7 Listening Mode Availability',
    sayHelp: true,
    traditionalSafetyAlertApps: false,
  },
  {
    name: 'Unlimited Alerts',
    sayHelp: true,
    traditionalSafetyAlertApps: false,
  },
  {
    name: 'Up to 5 Emergency Contacts',
    sayHelp: true,
    traditionalSafetyAlertApps: false,
  },
  {
    name: 'Available on Azure Platform',
    sayHelp: true,
    traditionalSafetyAlertApps: false,
  },
  {
    name: '24/7 Say Help Technical/Operational Support',
    sayHelp: true,
    traditionalSafetyAlertApps: false,
  },
  {
    name: 'Partner/Distributor Dashboards Availability',
    sayHelp: true,
    traditionalSafetyAlertApps: false,
  },
];

function ComparisonChart() {
  const { classes } = useStyles();

  return (
    <Table striped>
      <thead>
        <tr>
          <th>Capabilities</th>
          <th>Traditional Safety Alert Apps</th>
          <th className={classes.sayHelpWidth}>Say Help</th>
        </tr>
      </thead>
      <tbody>
        {capabilities.map((capability, index) => (
          <tr key={index}>
            <td>{capability.name}</td>
            <td>
              {capability.traditionalSafetyAlertApps ? (
                <IconCheck size={24} color="green" />
              ) : (
                <IconX size={24} color="red" />
              )}
            </td>
            <td>
              {capability.sayHelp ? (
                <IconCheck size={24} color="green" />
              ) : (
                <IconX size={24} color="red" />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default ComparisonChart;

const useStyles = createStyles(theme => ({
  sayHelpWidth: {
    minWidth: 80,
  },
}));
