import {
  createStyles,
  Title,
  Text,
  Button,
  Center,
  Space,
  Anchor,
  Image,
  Box,
  Stack,
  BackgroundImage,
  Modal,
  UnstyledButton,
  Menu,
  Group,
  Divider,
  List,
  TextInput,
  Textarea,
  ActionIcon,
  Notification,
  Loader,
  Table,
  Container,
  Grid,
  Accordion,
} from '@mantine/core';
import { ElfsightWidget } from 'react-elfsight-widget';
import { themeColors } from '../../config/themeSettings';
import { Link, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { IconArrowUpRight, IconCheck, IconX } from '@tabler/icons';

import { useForm } from 'react-hook-form';

import image from '../../pages/assets/people-taking-photos-food.jpg';

const FoodDelivery = () => {
  const { classes } = useStyles();

  return (
    <div>
      <div className={classes.dateCategory}>
        December 26, 2023 | Delivery Safety
      </div>
      <section className={classes.header}>
        <Title order={1} className={classes.title}>
          Enhancing Driver Safety in Food Delivery Apps
        </Title>
      </section>
      <div className={classes.container}>
        <Box className={classes.sectionCenter}>
          <Image src={image} />
          <div className={classes.imageAttribution}>
            Image by{' '}
            <a href="https://www.freepik.com/free-photo/people-taking-photos-food_23430207.htm#query=food%20delivery%20app%20driver&position=8&from_view=search&track=ais&uuid=e5d13aca-5a59-4b30-b960-76be8036de55">
              Freepik
            </a>
          </div>
          <section className={classes.headerQuestion}>
            <Title order={3} className={classes.title}>
              Are food delivery drivers safe in today’s World? How can food
              delivery applications enhance their safety features to improve
              driver and customer safety?
            </Title>
          </section>
          <section className={classes.paragraph}>
            <Space h="md" />
            <Text>
              In today’s fast-paced world, we often use food delivery
              applications like DoorDash, GrubHub, Uber Eats, and many others to
              have our food delivered to our home, work, car, or to a specific
              location on a map. The convenience of food delivery applications
              remains a significant advantage for everyone. Two-Thirds of
              consumers say they didn’t mind spending more money to have food
              delivered to them to save time and effort.
            </Text>
            <Space h="md" />
            <Text>
              Since the COVID-19 pandemic, food delivery applications have
              doubled and continue to average between 5% to 10% growth every
              year. Food delivery applications will become a trillion-dollar
              global market. Some projections reach $1.7 Trillion in 2028. In
              2022, Uber Eats generated approximately 10.9 billion in global
              revenues, surpassing food delivery competitors Delivery Hero and
              DoorDash, whose worldwide revenues amounted to about 9.2 billion
              and 6.6 billion. Food delivery applications have earned their
              place as a staple of the food industry.
            </Text>
            <Space h="md" />
            <Text>
              Due to the massive competition within this major growth area, food
              delivery applications are focused on creating innovation in the
              following areas: creating seamless user experience, improving
              payment methods, leveraging customer data, fostering strong
              relationships with restaurants, leveraging social media, offering
              loyalty programs, partnering with local businesses and events and
              continuously improving their application through innovation.
            </Text>
            <Space h="md" />
            <Text>
              What is missing from these food delivery future innovation areas?
              Food delivery applications are not focused on driver and customer
              safety. Yes, majority of food deliveries are safe with no
              incidents. However, tell this to a delivery driver who is
              constantly driving to homes, traveling through neighborhoods, and
              meeting new people day and night. Delivery drivers are put in many
              unknown situations, and they are taking major safety risks for a
              potential $5- or $10-dollar tip.
            </Text>
            <Space h="md" />
            <Text>
              A 2023 Georgetown Report that surveyed delivery drivers identified
              the following safety experiences:
            </Text>
            <Space h="md" />
            <List withPadding className={classes.boldList}>
              <List.Item>
                51% of the drivers felt unsafe or feared for their physical
                well-being on the job.
              </List.Item>
              <List.Item>
                41% of drivers reported experiencing harassment and assaults.
              </List.Item>
              <List.Item>
                Majority of drivers don’t report incidents because they believe
                the company wouldn’t care or they be penalized for an incomplete
                order.
              </List.Item>
              <List.Item>
                23% of workers experienced vehicle collisions or accidents.
              </List.Item>
              <List.Item>
                Report recommends enacting US legislation to ensure fair and
                just working conditions in the Food Delivery Industry.
              </List.Item>
            </List>
            <Space h="md" />
            <Text>
              There are safety incidents reported across the United States with
              food delivery drivers. Here are a few to keep in mind:
            </Text>
            <Space h="md" />
            <List withPadding className={classes.boldList}>
              <List.Item>
                A female delivery driver was shot during a food delivery. The
                customer opened the door, asked for her phone, she declined and
                they shot her to death.{' '}
              </List.Item>
              <List.Item>
                In San Francisco, a homeowner shot at a delivery driver for
                turning onto his property.
              </List.Item>
              <List.Item>
                A Florida man was charged with killing and dismembering a food
                delivery driver.
              </List.Item>
              <List.Item>
                A woman was kidnapped and sexually assaulted while making a food
                delivery to a hotel.
              </List.Item>
            </List>
            <Space h="md" />
            <Text>
              According to the occupational fatalities and injury data from the
              Bureau of Labor Statistics, ride sharing, and delivery driving are
              among the deadliest occupations in the country. Most deaths and
              injuries are from traffic accidents, but data also shows drivers
              are more at risk of assaults than any other occupations.
            </Text>
            <Space h="md" />
            <Text>
              Some companies are attempting to address this issue by including
              safety components within their driver applications, however they
              are limited and much more work needs to be done. Some of the food
              delivery driver applications have an emergency push button that
              sends an alert to family and friends. There are components to
              easily call 911 (but nothing more advanced that currently exists
              on the current SOS functions on user phones). Overall, security
              features are extremely limited and will not help a delivery driver
              if they are unexpectedly attacked, robbed, etc.
            </Text>
            <Space h="md" />
            <Text>
              Food Delivery Applications need to focus on driver and customer
              safety functions before it’s too late. Personal Safety
              applications in Today’s Play Stores are becoming more innovative
              every day and there is a new application called Say Help that
              would significantly improve food delivery applications to include
              DoorDash, Uber Eats, Grubhub, etc.
            </Text>
            <Space h="md" />
            <Text>
              Say Help is a new personal safety emergency alert application that
              is now available in 177 countries in both play stores. Say Help
              will improve the safety of delivery drivers and their customers.
            </Text>
            <Space h="md" />
            <Text>
              Here is where a new application like Say Help will improve the
              safety of food delivery drivers by the following areas:
            </Text>
            <Space h="md" />
            <List withPadding className={classes.boldList}>
              <List.Item>
                Drivers will be able to initiate an Emergency Alert with their
                voice when they are unable to click on push buttons to initiate
                an alert. There are many scenarios that causes a driver to be
                unable to click on a push button during an emergency (assault,
                robbery, heart attack, car accident).
              </List.Item>
              <List withPadding className={classes.nestedList}>
                <List.Item>
                  With Say Help, drivers will be able to initiate an emergency
                  alert via a Voice Trigger by saying “HELP, HELP, HELP” or by
                  saying one of their five customized words or phrases.
                </List.Item>
              </List>
              <List.Item>
                Drivers will have a real-time streaming alert room that allows
                emergency contacts and monitoring calling centers to view, hear,
                and track via GPS an emergency alert created by the Driver.
              </List.Item>
              <List.Item>
                Real-time alert room can be utilized by delivery drivers for
                non-emergency scenarios to support delivery drivers for showing
                proof that they dropped off the food, etc.
              </List.Item>
              <List withPadding className={classes.nestedList}>
                <List.Item>
                  Drivers are using their phones to take photos & purchasing
                  clip on video cameras that show the food was dropped off at
                  the correct location.
                </List.Item>
              </List>
              <List.Item>
                Eliminate the possibility for delivery drivers to press push
                buttons to initiate emergency support while a motor vehicle is
                in motion.
              </List.Item>
              <List withPadding className={classes.nestedList}>
                <List.Item>
                  At times, delivery drivers utilize the application while their
                  car is in motion, causing potentially dangerous situations.
                </List.Item>
              </List>
              <List.Item>
                Ability to integrate the Say Help voice activation capability
                across other Food Delivery functions to include the order
                acceptance process.
              </List.Item>
            </List>
            <Space h="md" />
            <Text>
              Food delivery applications need to advance their security features
              and ordering processes within their application to improve driver
              safety. More than half of delivery drivers feel unsafe, this is an
              area within this booming industry that needs to be improved before
              it’s too late. With the implementation of Say Help personal safety
              application, this industry can reduce these safety concerns
              identified by the Georgetown report.
            </Text>
            <Space h="md" />
          </section>
          <Divider></Divider>
          <Text className={classes.footNote}>
            Footnotes: Combination of derivative and original content. Sources:
            www.google.com, www.Forbes.com, www.cbs.com, www.bloomberg.com,
            Georgetown University Report, US Labor Statistics Bureau.{' '}
          </Text>
        </Box>
      </div>
    </div>
  );
};

export default FoodDelivery;

const useStyles = createStyles(theme => ({
  root: {
    fontFamily: 'Poppins',
    backgroundColor: themeColors.white,
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
  },

  dateCategory: {
    fontSize: 14,
    fontWeight: 'normal',
    marginBottom: 10,
    textAlign: 'center',
    color: '#757575',
  },

  differences: {
    width: '100%',
  },

  wrapper: {
    paddingTop: `calc(${theme.spacing.xl}px * 2)`,
    paddingBottom: `calc(${theme.spacing.xl}px * 2)`,
  },

  item: {
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
  },

  container: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },

  containerDark: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,

    position: 'relative',
  },

  flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',

    // General Gap between header buttons title and elements
    gap: 20,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    gridTemplateRows: 'auto',
    justifyContent: 'space-between',

    '@media (max-width: 425px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      justifyContent: 'center',
      alignContent: 'center',
      justifyItems: 'center',
    },
    '@media (max-width: 1000px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      justifyContent: 'center',
      alignContent: 'center',
      justifyItems: 'center',
      gap: 20,
    },
  },
  terms: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    // alignItems: 'center',
    justifyContent: 'center',

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 50,
    },

    '@media (max-width: 425px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 0,
    },
  },

  menuFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  menu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // alignItems: 'center',
    justifyContent: 'center',
    gpa: 10,
    borderRadius: 6,
    backgroundColor: themeColors.menu,
    height: 40,

    '@media (max-width: 550px)': {
      display: 'none',
    },
  },

  menuLink: {
    color: themeColors.white,
    paddingLeft: 34,
    paddingRight: 34,

    borderRight: '1px solid #D8D8D8',

    ':last-child': {
      borderRight: 'none',
    },
  },

  menuLinkActive: {
    color: themeColors.white,
    fontWeight: 'bold',
    paddingLeft: 34,
    paddingRight: 34,

    borderRight: '1px solid #D8D8D8',

    ':last-child': {
      borderRight: 'none',
    },

    '@media (max-width: 425px)': {
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: 16,
    },
  },

  badgesTop: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // alignItems: 'center',
    justifyContent: 'center',
    gpa: 20,

    '@media (max-width: 425px)': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 20,
    },

    '@media (min-width: 426px)': {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 20,
    },

    '@media (min-width: 1023px)': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 20,
    },
  },

  anchor: {
    color: themeColors.anchor,
    fontSize: 14,
  },
  badges: {
    zIndex: 100,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 20,
  },

  apppreview: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 20,
  },

  apppreviewPosition: {
    // position: 'absolute',
    // top: 0,
  },

  header: {
    textAlign: 'center',
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 900px)',
    gridTemplateRows: 'auto',
    justifyContent: 'center',
    justifyItems: 'center',
    gap: 20,
    // gridColumn:

    // maxWidth: 1000,
  },

  headerQuestion: {
    textAlign: 'center',
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 900px)',
    gridTemplateRows: 'auto',
    justifyContent: 'center',
    justifyItems: 'center',
    gap: 20,
    marginTop: 20,
    // gridColumn:

    // maxWidth: 1000,
  },

  boldList: {
    fontWeight: 'bold',
    paddingRight: 20,
  },

  nestedList: {
    fontWeight: 'normal',
  },

  footNote: {
    fontSize: 12,
    marginTop: 20,
    color: '#757575',
  },

  imageAttribution: {
    fontSize: 12,
    marginTop: 10,
    color: '#757575',
  },

  title: {
    fontFamily: 'Poppins',
    // fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.anchor,
  },

  paragraph: {
    fontFamily: 'Poppins',
    // fontSize: 26,
    //fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    //textAlign: 'center',
    color: themeColors.anchor,
  },

  titleWhite: {
    fontFamily: 'Poppins',
    fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    // maxWidth: 900,
    textAlign: 'center',
    color: themeColors.white,

    '@media (min-width: 768px) and (max-width: 1023px) ': {
      fontSize: 20,
    },
    '@media (max-width: 767px)': {
      fontSize: 27,
    },
  },
  subtitle: {
    fontFamily: 'Poppins',
    // fontSize: 13,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.15,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.anchor,
  },

  redTitle: {
    fontFamily: 'Poppins',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    letterSpacing: 4,
    textAlign: 'center',
    color: '#ec1d23',
    textTransform: 'uppercase',
  },

  ctaTitle: {
    fontFamily: 'Poppins',
    fontSize: '0.85rem',
    fontWeight: 'bold',
    letterSpacing: 1,
    textAlign: 'center',
    // color: '#ec1d23',
    textTransform: 'uppercase',
  },

  redbutton: {
    // updated fonts
    fontSize: 14,
    fontWeight: 'bold',
    color: themeColors.white,
    backgroundColor: '#1E1E1E',
    width: 250,
  },

  rightIcon: {
    marginLeft: 0,
  },

  containerGrey: {
    backgroundColor: themeColors.backgound,
    // height: 900,
    // minHeight: 400,
  },

  darksectionMiddle: {
    paddingLeft: 20,
    paddingRight: 20,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,

    '@media (max-width: 767px) ': {
      paddingBottom: 60,
    },

    '@media (min-width: 768px) and (max-width: 1023px)': {
      paddingBottom: 80,
    },
    '@media (min-width: 1024px) ': {
      paddingBottom: 80,
    },
  },

  darksection: {
    paddingLeft: 20,
    paddingRight: 20,
  },

  photosContent: {
    maxWidth: '33%',
    '@media (max-width: 768px)': {
      maxWidth: '390px',
    },
  },

  positionUp: {
    width: '100%',
    position: 'absolute',
    bottom: '-20vw',
    // top: -330,
    left: 0,
    right: 0,
    // margin: '0 auto',

    '@media (min-width: 1024px)': {
      bottom: '-210px',
    },
    // '@media (min-width: 768px) and (max-width: 1023px)': {
    //   top: '-38vw',
    // },
    // '@media (max-width: 767px)': {
    //   top: '-38vw',
    // },
    // '@media (min-width: 1024px)': {
    //   top: '-310px',
    //   // top: '-330px',
    //   // left: '20%',
    //   // right: '20%',
    // },
    // position: 'absolute',
    // top: -100,
    // '@media (min-width: 768px) and (max-width: 1023px)': {
    //   transform: 'translate(0,-33vw)',
    // },
    // '@media (max-width: 767px)': {
    //   transform: 'translate(0,-33vw)',
    // },
    // '@media (min-width: 1024px)': {
    //   transform: 'translate(0,-337px)',
    // },
  },

  appPreview: {
    maxWidth: 854,
    width: '80%',
    height: 'auto',
    margin: '0 auto',

    '@media (min-width: 768px) and (max-width: 1023px)': {
      height: 'auto',
      margin: '0 auto',
      width: '80%',
    },
    '@media (max-width: 767px) ': {
      height: 'auto',
      margin: '0 auto',
      width: '80%',
    },
  },

  space: {
    // height: 1215,
    // minHeight: 400,
    paddingBottom: '25vw',

    '@media (min-width: 1024px)': {
      paddingBottom: '150px',
    },
  },

  sectionCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '90vw',
    maxWidth: 900,
  },

  photosFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    flexBasis: '33.3333333333%',
    width: '100%',

    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },

  contentflex: {
    // paddingTop: 30,
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 1fr) minmax(min-content, 1fr)',
    gridTemplateRows: 'auto',

    justifyContent: 'space-evenly',

    gap: 20,

    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
    },
  },

  contentflexReverse: {
    paddingTop: 50,
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',

    gap: 20,

    '@media (max-width: 767px)': {
      flexDirection: 'column',
    },
  },

  titleRedLine: {
    // fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.88,
    letterSpacing: 'normal',
    color: themeColors.text,

    borderLeft: '6px solid #EC1D23',
    paddingLeft: 20,
  },

  description: {
    // fontSize: 13,
    fontWeight: 'normal',
    lineHeight: 2.15,
    letterSpacing: 'normal',
    paddingLeft: 30,
    paddingRight: 30,
  },

  containerDarkImage: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 20,
    paddingBottom: 20,

    '@media (min-width: 1024px)': {
      // paddingTop: 100,
      // paddingBottom: 100,
    },
  },

  titleOutline: {
    fontFamily: 'Poppins',
    WebkitTextStroke: '1px #ec1c24',
    fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: themeColors.text,
  },

  advantagesFlex: {
    // paddingTop: 50,

    // paddingLeft: 30,
    // paddingRight: 30,

    '@media (max-width: 768px)': {
      paddingTop: 20,
    },

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    gap: 20,
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },

  descriptionWhite: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: 'normal',

    color: themeColors.white,
    '@media (max-width: 767px)': {
      textAlign: 'center',
      fontSize: 16,
      lineHeight: 2.13,
    },
    '@media (min-width: 1023px)': {
      fontSize: 20,
    },
  },

  advantagesDescriptionFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 350,
    '@media (min-width: 768px)': {
      alignItems: 'flex-start',
    },
  },

  advFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 50,
    paddingBottom: 50,
    paddingLeft: 20,
    paddingRight: 20,

    '@media (min-width: 768px)': {
      paddingLeft: 30,
      paddingRight: 30,
    },
    '@media (min-width: 1024px)': {
      paddingTop: 100,
      paddingBottom: 100,
    },
  },

  phonePreview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 20,

    '@media (min-width: 768px)': {
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
  },

  phonePreviewImages: {
    maxWidth: 150,
    width: '80%',
    // height: 'auto',
    // margin: '0 auto',
  },

  phonePreviewDescriptionTablet: {
    paddingTop: 20,

    '@media (min-width: 1024px)': {
      paddingTop: 50,
      paddingBottom: 100,
      maxWidth: 450,
    },
  },

  phonePreviewDescription: {
    paddingTop: 20,

    '@media (min-width: 768px)': {
      paddingTop: 130,
      paddingBottom: 130,
      maxWidth: 350,
    },
  },

  appPreviewImages: {
    maxWidth: 550,
    width: '100%',
  },

  flexReverse: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },

  flexBottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    // General Gap between header buttons title and elements
    gap: 20,

    paddingLeft: 50,
    paddingRight: 50,
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
  },

  flexBottomLogo: {
    alignItems: 'center',
    '@media (min-width: 768px)': {
      alignItems: 'flex-start',
    },
  },

  termsBottom: {
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // alignItems: 'center',
    justifyContent: 'space-between',

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 50,
    },
  },

  termsSocial: {
    zIndex: 100,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    gap: 20,

    // '@media (min-width: 768px)': {
    //   flexDirection: 'row',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    //   gap: 50,
    // },
  },

  cta: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.7,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.red,

    '@media (min-width: 768px)': {
      fontSize: 20,
    },
  },

  bottomImagePosition: {
    position: 'absolute',
    bottom: 0,
    '@media (max-width: 767px)': {
      // width: 1100,
      // height: 300,
      minWidth: 1020,
      objectPosition: 'center',
      imagePosition: 'center',
    },
    '@media (min-width: 768px) and (max-width: 1023px)': {
      minWidth: 1020,

      objectPosition: 'center',
      objectGit: 'cover',
      imagePosition: 'center',
    },

    '@media (min-width: 1024px)': {
      maxWidth: 1600,
      objectPosition: 'center',
      imagePosition: 'center',
    },
  },

  containerGreyBottom: {
    backgroundColor: themeColors.backgound,
    height: 110,
    position: 'relative',
    '@media (min-width: 768px)': {
      height: 110,
    },
    overflowX: 'clip',
    // overflowY: 'initial',
  },

  containerDarkBottom: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 200,
    position: 'relative',
    '@media (min-width: 768px)': {
      paddingTop: 160,
    },
  },
  redTitleBox: {
    paddingTop: '25vw',
    '@media (min-width: 1024px)': {
      paddingTop: 250,
    },
  },

  price: {
    // fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'right',
  },

  priceDescription: {
    // maxWidth: '70%',
  },

  priceSign: {
    fontSize: 14,
    //extAlign: 'right',
    whiteSpace: 'nowrap',
  },

  input: {
    backgroundColor: '#FAFAFA',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    paddingBottom: 15,
    borderRadius: 10,
    border: '1px solid #F0F0F0',
    color: '#F6F6F6',
    fontSize: 14,
    minHeight: 55,
  },

  inputArea: {
    backgroundColor: '#FAFAFA',
    padding: 15,
    borderRadius: 10,
    border: '1px solid #F0F0F0',
    color: '#F6F6F6',
    height: 190,
    fontSize: 14,
  },

  greenButton: {
    fontSize: 14,
    fontWeight: 'bold',
    color: themeColors.white,
    backgroundColor: '#23B33A',
    paddingLeft: 50,
    paddingRight: 50,
    // paddingTop: 30,/
    // paddingBottom: 30,
    height: 70,
  },

  leftIcon: {
    marginLeft: 0,
  },

  gridSection: {
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, auto) minmax(min-content, auto)',
    gridTemplateRows: 'auto',

    justifyContent: 'space-between',
    alignItems: 'start',

    gap: 20,
    // border: '1px solid black',

    '@media (max-width: 425px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      gap: 0,
    },
  },

  titleSection: {
    '@media (max-width: 425px)': {
      textAlign: 'center',
    },
  },

  contentSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    width: '100%',
    alignItems: 'flex-end',

    '@media (max-width: 425px)': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 10,
    },
  },
}));
