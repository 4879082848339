/**
 * @type {UserConfig}
 */
const pluginConfig = {
  current_lang: 'en',
  autoclear_cookies: true, // default: false
  page_scripts: true, // default: false

  // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
  // delay: 0,                               // default: 0
  // auto_language: null                     // default: null; could also be 'browser' or 'document'
  // autorun: true,                          // default: true
  // force_consent: false,                   // default: false
  // hide_from_bots: false,                  // default: false
  // remove_cookie_tables: false             // default: false
  // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
  // cookie_expiration: 182,                 // default: 182 (days)
  // cookie_necessary_only_expiration: 182   // default: disabled
  // cookie_domain: location.hostname,       // default: current domain
  // cookie_path: '/',                       // default: root
  // cookie_same_site: 'Lax',                // default: 'Lax'
  // use_rfc_cookie: false,                  // default: false
  // revision: 0,                            // default: 0

  onFirstAction: function (user_preferences, cookie) {
    // callback triggered only once
    const analyticsEnabled =
      window.CookieConsentApi.allowedCategory('analytics');
    console.log(`analytics ${analyticsEnabled ? 'enabled' : 'disabled'}`);
  },

  onAccept: function (cookie) {
    // ...
  },

  onChange: function (cookie, changed_preferences) {
    // ...
  },

  languages: {
    en: {
      consent_modal: {
        title: 'We use cookies!',
        description:
          'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
        primary_btn: {
          text: 'Accept all',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Decline all',
          role: 'accept_necessary', // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: 'Cookie Settings',
        save_settings_btn: 'Save settings',
        accept_all_btn: 'Accept all',
        reject_all_btn: 'Reject all',
        close_btn_label: 'Close',
        cookie_table_headers: [
          { col1: 'Name' },
          { col2: 'Domain' },
          { col3: 'Expiration' },
          { col4: 'Description' },
        ],
        blocks: [
          {
            title: 'Cookies',
            description:
              'We use cookies! Some are necessary, but some are optional. Why do we use them? For analyzing site usage, enhancing site navigation and improving our service. If you decline, you may still see some cookies, but they will be fully anonymized. For more details relative to cookies and other sensitive data, please read the full <a href="https://sayhelp.com/privacy" class="cc-link">privacy policy</a>.',
          },
          {
            title: 'Necessary',
            description:
              'These cookies are necessary for the website to work properly and can’t be switched off in our systems. They’re usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in, or filling in forms. Learn more about necessary cookies.',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: 'Analytics',
            description:
              'These cookies help us to understand how visitors engage with the website. We may use a set of cookies to collect information and report site usage statistics. In addition to reporting site usage statistics, data collected may also be used, together with some of the advertising cookies described, to help show more relevant ads across the web and to measure interactions with the ads we show. Learn more about analytics cookies.',
            toggle: {
              value: 'analytics', // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              // list of all expected cookies
              {
                col1: '^_ga', // match all cookies starting with "_ga"
                col2: 'google.com',
                col3: '2 years',
                col4: 'description ...',
                is_regex: true,
              },
              {
                col1: '_gid',
                col2: 'google.com',
                col3: '1 day',
                col4: 'description ...',
              },
            ],
          },
          {
            title: 'Advertisement',
            description:
              'We may use cookies to make our ads more engaging and valuable to site visitors. Some common applications of cookies are to select advertising based on what’s relevant to a user; to improve reporting on ad campaign performance; and to avoid showing ads the user has already seen; to connect a website to a third-party social media platform. Learn more about advertisement cookies.',
            toggle: {
              value: 'targeting',
              enabled: false,
              readonly: false,
            },
          },
          {
            title: 'Functionality',
            description:
              'We use a set of cookies that are optional for the website to function. They’re usually only set in response to information provided to the website to personalize and optimize your experience, as well as remembering your chat history. Learn more about functionality cookies.',
            toggle: {
              value: 'functionality',
              enabled: false,
              readonly: false,
            },
          },
          {
            title: 'More information',
            description:
              'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="https://sayhelp.com/home/pricing">contact us</a>.',
          },
        ],
      },
    },
  },
};

export default pluginConfig;
