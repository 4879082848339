import React, { useEffect } from 'react';
import pluginConfig from './CookieConsentConfig';
import 'vanilla-cookieconsent';

const CookieConsentComponent = () => {
  useEffect(() => {
    if (!document.getElementById('cc--main')) {
      window.CookieConsentApi = window.initCookieConsent();
      window.CookieConsentApi.run(pluginConfig);
    }
  }, []);

  return null;
};

export default CookieConsentComponent;
