import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  createStyles,
  Drawer,
  Image,
  UnstyledButton,
} from '@mantine/core';
import { IconAdjustments } from '@tabler/icons';
import realtimeData from '../assets/realtime-data.svg';
import filter from '../assets/filter.svg';
import AppLogoMobile from './AppLogoMobile';

import AppAnimation from './AppAnimation';
import LocationProfileMobile from './LocationProfileMobile';
import InformationProfileMobile from './InformationProfileMobile';
import MapSettingsMobile from './MapSettingsMobile';

import useUserData from '../store/useUserStore';
import { themeColors } from '../config/themeSettings';
import { showField } from '../utils/formatProfile';

function HeaderMobile({ children, ...otherProps }) {
  const { getUserProfile } = useUserData();
  const userProfile = getUserProfile().userProfileData;

  const [mounted, setMounted] = useState(false);

  const [drawerRealTimeData, setDrawerRealTimeData] = useState(false);
  const [drawerInformationProfile, setDrawerInformationProfile] =
    useState(false);
  const [drawerMapSettings, setDrawerMapSettings] = useState(false);

  const { classes } = useStyles();

  const duration = 400;

  useEffect(() => {
    setMounted(true);
  }, []);

  const avatarTitle = showField(userProfile.name);

  const avatarImageUrl = userProfile.profileImage
    ? userProfile.profileImage
    : '';

  return (
    <div className={classes.container} {...otherProps} py={0} px={0}>
      <AppAnimation transition="fade" mounted={mounted} duration={duration}>
        <Box className={classes.logoNavigation}>
          <UnstyledButton className={classes.logoLeft}>
            <AppAnimation transition="pop" mounted={true}>
              <AppLogoMobile size={50} />
            </AppAnimation>
          </UnstyledButton>
          <Box style={{ marginLeft: 'auto' }} />

          <UnstyledButton
            onClick={() => setDrawerMapSettings(true)}
            px={10}
          >
            <Image width={40} src={filter} alt="filter" />
          </UnstyledButton>

          <UnstyledButton
            onClick={() => setDrawerRealTimeData(true)}
            px={10}
            className={classes.realTimeData}>
            <Image width={40} src={realtimeData} alt="Real Time Info" />
          </UnstyledButton>
          <UnstyledButton
            pl={10}
            onClick={() => setDrawerInformationProfile(true)}
            className={classes.profile}>
            <Avatar
              width={40}
              height={40}
              radius={20}
              color="red"
              src={avatarImageUrl}
              alt={avatarTitle}
              className={classes.avatar}
            />
          </UnstyledButton>

          <Drawer
            opened={drawerMapSettings}
            onClose={() => setDrawerMapSettings(false)}
            size={300}
            position="right"
            overlayOpacity={0.2}
            withCloseButton={false}
            withinPortal={true}
            className={classes.drawerRight}>
            <MapSettingsMobile
              onClose={() => setDrawerMapSettings(false)}
            />
          </Drawer>

          <Drawer
            opened={drawerRealTimeData}
            onClose={() => setDrawerRealTimeData(false)}
            size={300}
            position="right"
            overlayOpacity={0.2}
            withCloseButton={false}
            withinPortal={true}
            className={classes.drawerRight}>
            <LocationProfileMobile
              onClose={() => setDrawerRealTimeData(false)}
            />
          </Drawer>

          <Drawer
            opened={drawerInformationProfile}
            onClose={() => setDrawerInformationProfile(false)}
            size={320}
            position="right"
            overlayOpacity={0.2}
            withCloseButton={false}
            className={classes.drawerRight}>
            <InformationProfileMobile
              onClose={() => setDrawerInformationProfile(false)}
            />
          </Drawer>
        </Box>
      </AppAnimation>
    </div>
  );
}

export default HeaderMobile;

const useStyles = createStyles({
  container: {
    width: '100%',
    minHeight: 70,

    paddingLeft: 20,
    paddingRight: 20,

    backgroundColor: themeColors.backgound,
  },

  drawerRight: {},

  logoLeft: {
    alignItems: 'flex-start',
  },

  logoNavigation: {
    display: 'flex',
    justifyContent: 'flex-end',

    alignItems: 'center',

    paddingTop: 10,
  },

  logoContainer: {},
  logoTextContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: 0,
    paddingTop: 7,
    paddingBottom: 18,
  },

  logo: {
    width: 50,
    height: 50,
  },

  profile: {},

  avatar: {
    width: 40,
    height: 40,
  },
  realTimeData: {
    //
  },
});
