import React, { useEffect, useState } from 'react';
import { createStyles, Box, CloseButton } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';

import { themeColors } from '../config/themeSettings';

import AppAnimation from './AppAnimation';
import MapSettingsData from './MapSettingsData';

function MapSettingsMobile({ onClose, children, ...otherProps }) {
    const [mounted, setMounted] = useState(false);
    const { classes } = useStyles();

    const duration = 400;

    useEffect(() => {
        setMounted(true);
    }, []);

    const { height } = useViewportSize();

    return (
        <Box className={classes.root} style={{ height }} {...otherProps}>
            <Box className={classes.container} style={{ height }}>
                <AppAnimation
                    transition="pop-top-left"
                    mounted={mounted}
                    duration={duration * 2}>
                    <CloseButton
                        title="Close"
                        variant="transparent"
                        iconSize={30}
                        pt={30}
                        onClick={onClose}
                        style={{ color: 'white' }}
                    />
                    <MapSettingsData />
                </AppAnimation>
            </Box>
        </Box>
    );
}

export default MapSettingsMobile;

const useStyles = createStyles({
    root: {
        height: '100vh',
        height: '-webkit-fill-available',

        backgroundColor: themeColors.backgound,
    },
    container: {
        paddingLeft: 20,
        paddingRight: 20,
        height: '100vh',
        height: '-webkit-fill-available',

        overflowY: 'scroll',
        backgroundColor: themeColors.backgound,

        '::-webkit-scrollbar': {
            display: 'none',
            WebkitAppearance: 'none',
            width: 0,
            height: 0,
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',

        '@supports (-webkit-touch-callout: none)': {
            height: '-webkit-fill-available',
        },

        WebkitMaskImage:
            'linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 15px, rgb(0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%)',
    },

    scrollArea: {},
    logoNavigation: {
        display: 'flex',
        justifyContent: 'space-between',

        paddingTop: 20,
        paddingBottom: 25,
    },

    backLink: {},
    logo: {},
    rightSide: {
        visibility: 'hidden',
    },
});