import React from 'react';
import { createStyles, Box, Group, Avatar, ThemeIcon } from '@mantine/core';
import Flag from 'react-flagkit';
import { IconWorld } from '@tabler/icons-react';

function AppFlag({
  children,
  country = 'US',
  size = 20,
  isGPS = false,
  ...otherProps
}) {
  const { classes } = useStyles();
  return (
    <Box className={classes.container} {...otherProps}>
      {/* <Avatar radius="lg" size={size}> */}
      {typeof country === 'string' && country && country !== 'ROW' ? (
        <>
          {isGPS ? (
            <>
              <ThemeIcon
                variant="outline"
                radius="xl"
                color="orange"
                size={size + 8}>
                <Avatar radius="lg" size={size}>
                  <Flag
                    country={String(country).toUpperCase()}
                    size={size + 15}
                  />
                </Avatar>
              </ThemeIcon>
            </>
          ) : (
            <>
              <Avatar radius="lg" size={size}>
                <Flag
                  country={String(country).toUpperCase()}
                  size={size + 15}
                />
              </Avatar>
            </>
          )}
        </>
      ) : (
        <>
          {isGPS ? (
            <>
              <ThemeIcon
                variant="outline"
                radius="xl"
                color="gray"
                size={size + 8}>
                <IconWorld stroke={1.5} size={size + 15} color="gray" />
              </ThemeIcon>
            </>
          ) : (
            <>
              <ThemeIcon color="gray" variant="transparent">
                <IconWorld stroke={1.5} size={size + 15} />
              </ThemeIcon>
            </>
          )}
        </>
      )}
      {/* </Avatar> */}
    </Box>
  );
}

export default AppFlag;

const useStyles = createStyles({
  container: {},
  country: {},
});
